/*------------------------------------
  Vertical Navbar Aside
------------------------------------*/

.navbar-vertical-aside-transition-on {
  .main,
  .navbar-vertical-aside,
  .navbar,
  .footer {
    transition: $navbar-vertical-transition;
  }
}

.navbar-vertical-aside-transition-on {
  .navbar-vertical-aside .navbar-vertical-footer {
    opacity: 0;
  }
}

.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
  padding-left: 0;
}

.navbar-fixed ~ .main .navbar-vertical-aside {
  top: 0;
}

[class*="navbar-vertical-aside-show"] {
  .navbar.splitted-content-navbar .navbar-brand {
    display: block;
  }

  .navbar-vertical.splitted-content-navbar .navbar-brand {
    display: flex;
  }

  .navbar-nav-wrap-content-left {
    padding-left: 0;
  }
}

.navbar-vertical-aside-mini-mode-hidden-elements {
  flex: 1;
}

// Brand
.navbar-vertical-aside {
  .navbar-brand {
    display: flex;
    align-items: center;
    height: $navbar-vertical-brand-height;
    padding-right: $navbar-vertical-brand-padding-x;
    padding-left: $navbar-vertical-brand-padding-x;
  }

  .navbar-brand-logo {
    display: block;
  }

  .navbar-brand-logo-mini {
    width: $navbar-vertical-brand-logo-mini-width;
    display: none;
  }
}

// Toggler
.navbar-aside-toggler {
  opacity: 0;
  z-index: $navbar-vertical-aside-toggler-z-index;
  width: $navbar-vertical-aside-toggler-btn-icon-width;
  height: $navbar-vertical-aside-toggler-btn-icon-height;
  font-size: $navbar-vertical-aside-toggler-btn-icon-font-size;
  color: $navbar-vertical-aside-toggler-color;
  background-color: $navbar-vertical-aside-toggler-bg-color;
  border: $navbar-vertical-aside-toggler-border-width solid $navbar-vertical-aside-toggler-border-color;
  @include border-radius($navbar-vertical-aside-toggler-border-radius-rounded);
  transition: .4s;
}

.navbar-vertical-aside .navbar-aside-toggler {
  position: absolute;
  top: calc(#{$navbar-vertical-brand-height / 2} - #{$navbar-vertical-aside-toggler-btn-icon-height / 2});
  right: -#{$navbar-vertical-aside-toggler-btn-icon-width / 2};
}

// Content
.navbar-vertical-content {
  height: calc(100% - #{$navbar-vertical-brand-height});
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bg-color;
  }
}

.nav-footer-item {
  display: none;

  &:last-child {
    margin-bottom: $navbar-footer-item-margin-y;
  }
}

// Modes
.navbar-vertical-aside {
  .navbar-vertical-aside-mini-mode & {
    position: fixed;

    .navbar-vertical-content {
      height: 100%;
    }

    .navbar-vertical-footer {
      position: static;
      margin-top: -$navbar-vertical-footer-border-width;

      .navbar-vertical-footer-list-item {
        position: static;

        .hs-unfold {
          position: static;
        }
      }
    }

    .navbar-vertical-footer-offset {
      padding-bottom: 0;
    }

    .navbar-vertical-footer-list-item {
      margin: $navbar-vertical-footer-list-item-margin-y 0;
    }

    .navbar-vertical-footer-dropdown {
      top: 0;
      left: $navbar-vertical-footer-dropdown-left-offset;
      margin: 0;

      &.hs-unfold-reverse-y {
        top: auto;
        bottom: 0;
      }
    }

    .navbar-vertical-footer-dropdown::before {
      position: absolute;
      top: 0;
      display: block;
      right: $navbar-vertical-menu-pseudo-right-offset;
      width: $navbar-vertical-menu-pseudo-width;
      height: 100%;
      content: '';
    }
  }

  &.navbar-vertical-aside-initialized {
    margin-left: 0;
  }

  .navbar-vertical-aside-closed-mode &.navbar-vertical-aside-initialized {
    margin-left: $navbar-vertical-margin-left;
  }

  .navbar-vertical-aside-closed-mode &.splitted-content-navbar.navbar-vertical-aside-initialized {
    margin-left: $navbar-vertical-margin-left - $splitted-content-mini-width;
  }

  .navbar-vertical-aside-closed-mode & {
    margin-left: $navbar-vertical-margin-left;
  }
}

// Nav
.navbar-vertical-aside .nav.nav-pills {
  padding-right: $navbar-vertical-aside-nav-pills-x;
  padding-left: $navbar-vertical-aside-nav-pills-x;
}

// Compact Mode
.navbar-vertical-aside-compact-mode {
  .navbar-vertical-aside {
    width: $navbar-vertical-compact-width !important;
  }

  .navbar-vertical .navbar-brand {
    margin-right: 0;
  }

  .nav-item > .nav-collapse {
    position: absolute;
    top: 0;
    left: $navbar-vertical-aside-compact-menu-left-offset;
    z-index: 2;
    min-width: $navbar-vertical-aside-compact-menu-min-width;
    background-color: $navbar-vertical-aside-compact-menu-bg-color;
    padding: $dropdown-padding-y $dropdown-padding-x;
    box-shadow: $navbar-vertical-aside-compact-menu-box-shadow;
    @include border-radius($navbar-vertical-aside-compact-menu-border-radius);
  }

  .navbar-vertical-aside {
    .nav-vertical.nav-pills .nav-collapse {
      text-align: left;
      padding-left: $nav-collapse-collapsed-padding-x;

      &::before {
        height: 100%;
      }

      .nav-collapse {
        position: static;
        box-shadow: none;
      }
    }

    .nav-collapse.collapsing {
      transition: none;
    }

    .nav.nav-pills.nav-vertical .nav-collapse .nav-link {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }
  }

  .navbar-vertical-aside {
    .nav.nav-vertical.nav-tabs .nav-collapse {
      text-align: left;

      .nav-link {
        padding-left: $navbar-vertical-menu-nav-link-padding-x;
      }

      .nav-collapse .nav-link {
        padding-left: $navbar-vertical-nav-collapse-link-padding-x / 1.5;
      }
    }
  }
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .dropdown-header {
  display: none;
}

.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
  display: block;
}

// Mini Mode
.navbar-vertical-aside-mini-mode .nav-collapse {
  position: absolute !important;
  top: 0;
  left: $navbar-vertica-aside-min-mode-nav-collapse-left-offset;
  z-index: 2;
  min-width: $navbar-vertica-aside-min-mode-nav-collapse-min-width;
  background-color: $navbar-vertica-aside-min-mode-nav-collapse-bg-color;
  box-shadow: $dropdown-box-shadow;
  @include border-radius($navbar-vertica-aside-min-mode-nav-collapse-border-radius);
  
  &::before {
    position: absolute;
    top: 0;
    left: -$navbar-vertica-aside-min-mode-nav-collapse-pseudo-left-offset;
    width: $navbar-vertica-aside-min-mode-nav-collapse-pseudo-width;
    height: $navbar-vertica-aside-min-mode-nav-collapse-pseudo-height;
    content: '';
    background: transparent;
  }

  .nav-link.dropdown-toggle::before {
    display: none;
  }
}

.navbar-vertical-aside-mini-mode .nav-vertical.nav-pills .nav-collapse::before {
  display: none;
}

.navbar-vertical-aside-mini-mode .nav .nav-collapse {
  padding: $dropdown-padding-y $dropdown-padding-x;
}

.navbar-vertical-aside-mini-mode {
  .nav-tabs .nav-collapse .nav-collapse {
    padding-left: 0;
  }

  .nav-tabs .nav-item.show .nav-item.show .nav-link.active {
    border-color: $nav-tabs-link-active-border-color;
  }
}

.navbar-vertical-aside .nav-link.dropdown-toggle {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: $navbar-vertical-aside-link-dropdown-toggle-pseudo-left-offset;
    content: '';
    width: $navbar-vertical-aside-link-dropdown-toggle-pseudo-width;
    height: $navbar-vertical-aside-link-dropdown-toggle-pseudo-height;
    background-color: $navbar-vertical-aside-link-dropdown-toggle-pseudo-bg-color;
    opacity: 0;
    @include border-radius($navbar-vertical-aside-link-dropdown-toggle-pseudo-border-radius);
    transform: translateY(-50%);
    transition: $navbar-vertical-aside-link-dropdown-toggle-pseudo-transition;
  }
}

.navbar-vertical-aside .nav-pills .nav-link.dropdown-toggle::before {
  left: $navbar-vertical-aside-link-dropdown-toggle-pseudo-width / 2;
}

.navbar-vertical-aside {
  .nav-item.show > .nav-link.dropdown-toggle::before,
  .nav-link.dropdown-toggle.active::before,
  .nav-link.dropdown-toggle:hover::before {
    background-color: $navbar-vertical-aside-link-dropdown-toggle-pseudo-bg-active-color;
  }
}

.navbar-vertical-aside-mini-mode .nav-link.dropdown-toggle::before {
  opacity: 1;
}

.navbar-vertical-aside-mini-mode {
  .navbar-vertical-aside .navbar-brand {
    margin-right: 0;
  }

  .nav {
    flex-wrap: nowrap;
  }
  
  .nav-link.dropdown-toggle::after {
    display: none;
  }

  .nav-collapse .nav-link.dropdown-toggle::after {
    display: block;
  }

  .nav-collapse .nav-collapse {
    position: static !important;
    box-shadow: none;
    min-width: 100%;
  }

  .nav-link-title {
    display: none;
  }
}

.navbar-vertical-aside-compact-mini-mode,
.navbar-vertical-aside-mini-mode {
  .navbar-vertical-container {
    position: static;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg-color;
    }
  }
}

// Splitted Content Mini
.navbar-vertical-aside .splitted-content-mini {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  z-index: 1;
}

// Overlay
.navbar-vertical-aside-mobile-overlay,
.navbar-vertical-aside-sub-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: $navbar-vertical-mobile-overlay-transition;
}

.navbar-vertical-aside-mobile-overlay {
  background-color: $navbar-vertical-mobile-overlay-bg-color;
}

.navbar-vertical-aside-mobile-overlay-transparent {
  background-color: transparent;
}

.has-navbar-vertical-aside.navbar-vertical-aside-sub-menu-opened .navbar-vertical-aside-sub-menu-overlay,
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode) .navbar-vertical-aside-mobile-overlay {
  z-index: $navbar-vertical-mobile-overlay-z-index;
  opacity: 1;
  width: 100%;
  height: 100%;
}

// Show
@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  $infix: breakpoint-infix($next, $grid-breakpoints);

  @include media-breakpoint-up($next) {
    .navbar-vertical {
      &.navbar-expand#{$infix} {
        flex-flow: column;

        .navbar-nav {
          flex-direction: column;
        }
      }
    }

    .navbar-vertical-aside-show#{$infix} {
      .main {
        padding-left: $navbar-brand-width;
      }

      .footer {
        margin-left: $navbar-brand-width;
      }

      .navbar:not(.navbar-vertical) {
        margin-left: $navbar-brand-width;
      }

      .splitted-content-navbar {
        margin-left: $navbar-brand-width + $splitted-content-mini-width;
      }

      &.navbar-vertical-aside-compact-mode {
        .main {
          padding-left: $navbar-vertical-compact-width;
        }

        .footer {
          margin-left: $navbar-vertical-compact-width;
        }

        .navbar:not(.navbar-vertical) {
          margin-left: $navbar-vertical-compact-width;
        }
      }

      &.splitted-content {
        .main {
          padding-left: $navbar-brand-width + $splitted-content-mini-width;
        }

        .footer {
          margin-left: $navbar-brand-width + $splitted-content-mini-width;
        }

        .navbar:not(.navbar-vertical) {
          margin-left: $navbar-brand-width + $splitted-content-mini-width;
        }
      }

      .navbar-vertical-aside {
        margin-left: 0;
      }

      .navbar:not(.navbar-vertical-aside) .navbar-brand {
        display: none;
      }

      .navbar-vertical.splitted-content-navbar .navbar-brand {
        display: none;
      }

      &.navbar-vertical-aside-mini-mode .navbar:not(.navbar-vertical) {
        margin-left: $navbar-left-mini-offset;
      }

      &.navbar-vertical-aside-mini-mode {
        .main {
          padding-left: $navbar-vertical-mini-width;
        }

        .footer {
          margin-left: $navbar-vertical-mini-width;
        }
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
        width: $navbar-vertical-mini-width;
      }

      &.navbar-vertical-aside-compact-mini-mode.splitted-content .splitted-content-navbar {
        width: $navbar-brand-width + $splitted-content-mini-width;
      }

      &.navbar-vertical-aside-compact-mini-mode.splitted-content {
        .navbar:not(.navbar-vertical),
        .splitted-content-main,
        .footer {
          padding-left: $navbar-brand-width + $splitted-content-mini-width;
        }

        .navbar:not(.navbar-vertical) {
          margin-left: $content-space-padding;
        }
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside-mini-mode-hidden-elements {
        display: none;
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
        display: block;
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
        display: none;
      }

      &:not(.navbar-vertical-closed-mode) .navbar-vertical-aside-mobile-overlay {
        display: none;
      }

      .splitted-content-navbar {
        .navbar-toggler-short-align,
        .navbar-toggler-full-align {
          display: none;
        }
      }

      .navbar-toggler,
      .navbar-vertical .navbar-toggler {
        display: none;
      }

      &.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-initialized .navbar-vertical-content {
        overflow-y: scroll;
        overflow-y: overlay;
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside .dropdown-header {
        display: none;
      }

      &.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-subtitle-replacer {
        display: block;
      }
  
      &.navbar-vertical-aside-mini-mode {
        .nav-footer-item {
          display: block;
        }

        .navbar-vertical-footer {
          display: none;
        }
      }

      .splitted-content-small {
        position: static;
        visibility: visible;
        transform: translateX(0);
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  .navbar-vertical-aside-show#{$infix} {
    @include media-breakpoint-down($breakpoint) {
      .navbar-brand-logo-mini {
        display: none;
      }

      // Height
      &.has-navbar-vertical-aside {
        .navbar-height {
          height: $navbar-height;
        }

        .navbar-nav {
          display: flex;
        }
      }

      &.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
        margin-left: $navbar-vertical-margin-left - 1;
      }
    }
  }
}

// Toggle
.has-navbar-vertical-aside {
  .navbar-toggler-short-align {
    display: block;
  }

  &.navbar-vertical-aside-closed-mode .navbar-toggler-short-align,
  &.navbar-vertical-aside-mini-mode .navbar-toggler-short-align {
    display: none;
  }

  .navbar-toggler-full-align {
    display: none;
  }

  &.navbar-vertical-aside-closed-mode .navbar-toggler-full-align,
  &.navbar-vertical-aside-mini-mode .navbar-toggler-full-align {
    display: block;
  }
}

.splitted-content-navbar .navbar-toggler-short-align {
  display: block;
}

.navbar-vertical-aside-closed-mode .splitted-content-navbar .navbar-toggler-short-align,
.navbar-vertical-aside-mini-mode .splitted-content-navbar .navbar-toggler-short-align {
  display: none;
}

.splitted-content-navbar .navbar-toggler-full-align {
  display: none;
}

.navbar-vertical-aside-closed-mode .splitted-content-navbar .navbar-toggler-full-align,
.navbar-vertical-aside-mini-mode .splitted-content-navbar .navbar-toggler-full-align {
  display: block;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.cover-image {
  max-height: 60px;
}

.navbar-dropdown-account {
  width: 16rem;
}

.no-arrow::after {
  display: none;
}

svg {
  margin-bottom: 3px;
}

.nav-link {
  font-size: 13px;
}

.react-datepicker-wrapper {
  all: unset;
}

.break-word {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

/*------------------------------------
  Default Styles
------------------------------------*/

html {
  position: relative;
  min-height: 100%;
}

a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: $outline-color;
}

figure {
  margin-bottom: 0;
}

dl {
  margin-bottom: 0;
}

dt {
  color: $dt-color;
}

dd {
  color: $dd-color;
  font-weight: $dd-font-weight;
  margin-bottom: $dd-margin-y;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: $primary;
  background-color: rgba($primary, .1);
}

::selection {
  color: $primary;
  background-color: rgba($primary, .1);
}

.bg-primary ::-moz-selection {
  color: $white;
  background-color: rgba($white, .1);
}

.bg-primary ::selection {
  color: $white;
  background-color: rgba($white, .1);
}
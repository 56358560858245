/*------------------------------------
  Form Attachment
------------------------------------*/

.form-attachment-btn {
	position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.form-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  opacity: 0;
}
/*------------------------------------
  Leaflet
------------------------------------*/

.leaflet {
  min-height: $leaflet-min-height;
  height: 100%;
  z-index: $leaflet-z-index;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: $leaflet-popup-wrapper-box-shadow;
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  @include border-radius($leaflet-popup-wrapper-border-radius);
}

.leaflet-bar a {
  &, &:hover {
    color: $leaflet-bar-color;
    background-color: $leaflet-bar-bg-color;
    border-bottom: none;
  }
  
  &:hover {
    color: $leaflet-bar-hover-color;
  }
}

.leaflet-touch .leaflet-bar a {
  width: $leaflet-bar-width;
  height: $leaflet-bar-height;
  line-height: $leaflet-bar-height;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: $leaflet-control-zoom-font-weight $leaflet-control-zoom-font-size $leaflet-control-zoom-font;
  text-indent: 0;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-family: $leaflet-control-zoom-font;
  font-size: $leaflet-control-zoom-font-size;
}

.leaflet-control-zoom-in {
  margin-bottom: $leaflet-control-zoom-margin-y;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
  @include border-radius($leaflet-control-zoom-border-radius);
}

.leaflet-popup {
  margin-bottom: $leaflet-popup-margin-y;
}
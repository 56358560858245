// Bootstrap Customs
// =========================================================

$font-family-base: "Inter", sans-serif !default;

$font-size-base: .875rem !default; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * .75 !default;
$font-size-sm: .8125rem !default;
$font-size-lg: 1rem !default;

$font-weight-normal: 400 !default;
$font-weight-semi-bold: 600 !default;

$h1-font-size: $font-size-base * 1.6125 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.3125 !default;
$h4-font-size: $font-size-base * 1.125 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base * .875 !default;

$headings-font-weight: $font-weight-semi-bold !default;
$headings-color: $gray-900 !default;

$display-font-sizes: (
        1: 5rem,
        2: 4rem,
        3: 3rem,
        4: 2rem,
        5: 1.5rem,
        6: 1.25rem
) !default;

$display-font-weight: $font-weight-semi-bold !default;

$lead-font-weight: $font-weight-normal !default;

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $font-size-sm
) !default;

// Colors
$body-bg: $white !default;
$body-color: $gray-700 !default;
$white-bg: #fff !default;
$link-color: $primary !default;
$link-hover-color: darken($primary, 7%) !default;
$active-color: $link-color !default;
$disabled-color: $gray-400 !default;
$text-muted: $gray-600 !default;
$white-10: rgba($white, .1) !default;
$white-70: rgba($white, .7) !default;
$link-light-hover-color: $white-70 !default;

// Links
$link-decoration: none !default;
$link-darker: darken($primary, 7%) !default;
$link-hover-opacity: .7 !default;
$stretched-link-z-index: 1 !default;

// Backgrounds
$overlay-soft-bg-color: rgba($dark, .25) !default;
$secondary-bg-color: transparent !default;
$secondary-hover-bg-color: rgba($primary, .1) !default;
$active-bg-color: $primary !default;
$disabled-bg-color: rgba($gray-300, .5) !default;
$primary-soft-bg-color: rgba(lighten($primary, 12%), .1) !default;

// Shadows
$box-shadow: 0rem .375rem 1.5rem 0rem rgba($gray-600, .125) !default;
$box-shadow-sm: 0rem .375rem .75rem rgba($gray-600, .075) !default;
$box-shadow-sm-hover: 0px .1875rem .75rem rgba($gray-600, .25) !default;
$box-shadow-lg: 0rem .6125rem 2.5rem .6125rem rgba($gray-600, .175) !default;
$box-shadow-xl: 0rem 3.75rem 5rem 0rem rgba($gray-600, .3) !default;

// Borders
$border-width: .0625rem !default;
$border-color: rgba($gray-300, .7) !default;

// Border Radius
$border-radius: .5rem !default;
$border-radius-sm: .3125rem !default;
$border-radius-lg: .75rem !default;
$border-radius-pill: 50rem !default;
$border-radius-rounded: 50% !default;

// Animations
$transition-timing: .3s !default;
$transition-timing-sm: .2s !default;
$transition-timing-md: .4s !default;
$transition-function: ease-in-out !default;

// Others
$outline-color: rgba($primary, .5) !default;

$list-inline-padding: .25rem !default;

$link-font-weight: $font-weight-semi-bold !default;

$letter-spacing: .03125rem !default;

$hr-margin-y: 1.75rem !default;
$hr-color: $border-color !default;
$hr-height: .03125rem !default;
$hr-opacity: 1 !default;

$code-font-size: 1em !default;

$kbd-color: $headings-color !default;
$kbd-bg: $gray-300 !default;

$dt-color: $body-color !default;
$dt-font-weight: $font-weight-normal !default;
$dd-color: $headings-color !default;
$dd-font-weight: $font-weight-semi-bold !default;
$dd-margin-y: .75rem !default;

// Spacing
$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer / 4,
        2: $spacer / 2,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 2,
        6: $spacer * 2.5,
        7: $spacer * 3,
        8: $spacer * 3.5,
        9: $spacer * 4,
        10: $spacer * 4.5,
);

// Content Spacer
$content-spacer: 1rem !default;
$content-spacers: (
        0: 0,
        1: ($content-spacer * 3),
        2: ($content-spacer * 5),
        3: ($content-spacer * 7.5),
        4: ($content-spacer * 10),
        5: ($content-spacer * 12.5)
) !default;

// List Padding
$list-padding-y: (
        1: .3125rem,
        2: .5rem,
        3: 1rem
) !default;

// List Padding
$list-padding-x: (
        1: .25rem,
        2: .5rem,
        3: 1rem
) !default;

// Number of horizontally stacked cards in one line
$card-group-number-of-cards: 4 !default;

// Z-index
$z-indexes: (
        n1: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        99: 99,
        999: 999
) !default;

// Grid containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1320px,
        xxl: 1480px
);

// Opacity
$opacity: (
        25: .25,
        50: .5,
        75: .75
) !default;

// Theme Colors
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $gray-100,
        "dark": $dark
);

// Color system
$theme-soft-colors: (
        "primary": $primary-soft,
        "secondary": $secondary-soft,
        "success": $success-soft,
        "info": $info-soft,
        "warning": $warning-soft,
        "danger": $danger-soft,
        "light": $light-soft,
        "dark": $dark-soft
) !default;

$min-contrast-ratio: 2.1 !default;


// Options
// Quickly modify global styling by enabling or disabling optional features.
// =========================================================

$enable-caret: false !default;
$enable-shadows: false !default;
$enable-rounded: true !default;
$enable-rfs: true !default;
$enable-validation-icons: false !default;
$enable-negative-margins: true !default;
$enable-smooth-scroll: false !default;


// Gradients
// =========================================================

$gradient-transparent: rgba($white, .0) !default;
$gradient-white: $white !default;
$gradient-dark: $dark !default;
$gradient-primary: $primary !default;
$gradient-info: $info !default;
$gradient-success: $success !default;
$gradient-danger: $danger !default;
$gradient-warning: $warning !default;


// Scrollbar
// =========================================================

// Width & Heights
$scrollbar-width: .6125rem !default;
$scrollbar-horizontal-height: $scrollbar-width !default;

// Backgrounds
$scrollbar-bg-color: rgba($gray-400, .6) !default;
$scrollbar-horizontal-bg-color: $scrollbar-bg-color !default;


// Button + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// =========================================================

$input-btn-focus-width: 0 !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color: rgba($gray-600, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur: 1rem !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y: .6125rem !default;
$input-btn-padding-x: 1rem !default;

$input-btn-padding-y-xs: .3125rem !default;
$input-btn-padding-x-xs: .75rem !default;
$input-btn-padding-y-sm: .5rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: .75rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-base !default;


// Card
// =========================================================

// Colors
$card-subtitle-color: $text-muted !default;
$card-text-color: $body-color !default;

// Backgrounds
$card-cap-bg: transparent !default;
$card-bg: $white-bg !default;

// Borders
$card-border-color: $border-color !default;
$card-border-radius: $border-radius-lg !default;

// Width & Heights
$card-body-height: 21.25rem !default;
$card-progress-height: .25rem !default;

// Shadows
$card-box-shadow: $box-shadow-sm !default;
$card-hover-shadow-box-shadow: $box-shadow-sm-hover !default;

// Padding
$card-spacer-y: 1.3125rem !default;
$card-spacer-x: $card-spacer-y !default;
$card-spacer-y-sm: 1rem !default;
$card-spacer-x-sm: $card-spacer-y-sm !default;
$card-spacer-y-lg: 2.5rem !default;
$card-spacer-x-lg: $card-spacer-y-lg !default;
$card-header-padding-y: 1rem !default;
$card-footer-padding-y: $card-header-padding-y !default;
$card-img-overlay-padding: $card-spacer-y $card-spacer-x !default;
$card-cap-padding-y: $card-spacer-y !default;
$card-cap-padding-x: $card-cap-padding-y !default;
$card-navbar-nav-padding-y: 1.5rem !default;
$card-navbar-nav-padding-x: .5rem !default;
$card-nav-link-padding-y: .5rem !default;

// Type
$card-subtitle-font-size: $font-size-base * .8125 !default;
$card-link-font-weight: $font-weight-semi-bold !default;
$card-table-tbody-th-font-weight: $font-weight-normal !default;

// Positions
$card-pinned-top-offset: 1rem !default;
$card-pinned-bottom-offset: $card-pinned-top-offset !default;
$card-pinned-left-offset: $card-pinned-top-offset !default;
$card-pinned-right-offset: $card-pinned-top-offset !default;

// Margins
$card-title-spacer-y: .25rem !default;
$card-title-margin-y: .5rem !default;
$card-subtitle-margin-y: .25rem !default;

// Animations
$card-transition: all $transition-timing-sm $transition-function !default;
$card-transition-translateY: -.1875rem !default;
$card-hover-shadow-transition: $transition-timing !default;
$card-dashed-body-transition: $transition-timing !default;

// Z-index
$card-avatar-group-z-index: $stretched-link-z-index + 1 !default;
$card-dropdown-z-index: $card-avatar-group-z-index + 1 !default;
$card-pinned-z-index: $stretched-link-z-index + 1 !default;

// Gap
$card-header-content-between-gap: .5rem !default;


// Forms
// =========================================================

// Colors
$input-color: $gray-900 !default;
$form-select-color: $body-color !default;
$input-placeholder-color: $gray-600 !default;
$input-focus-color: $input-color !default;
$input-focus-border-color: $input-btn-focus-color !default;
$input-group-addon-color: $gray-600 !default;
$form-label-color: $gray-900 !default;
$form-label-secondary-color: $gray-600 !default;
$form-check-label-color: $body-color !default;
$form-select-indicator-color: $gray-800 !default;
$form-check-bookmark-active-color: $warning !default;
$form-check-select-stretched-btn-active-color: $white !default;
$form-check-select-stretched-input-bg-image-color: $gray-300 !default;
$form-switch-darkmode-with-icons-input-before-color: $white !default;
$form-switch-darkmode-with-icons-input-after-color: $body-color !default;

// Width & Heights
$form-switch-input-before-width: .8125rem !default;
$form-switch-input-before-height: .8125rem !default;
$form-switch-darkmode-with-icons-input-before-width: .8125rem !default;
$form-switch-darkmode-with-icons-input-before-height: .8125rem !default;

// Backgrounds
$input-bg: $white-bg !default;
$input-group-addon-bg: $body-bg !default;
$input-group-hover-light-bg-color: $white-bg !default;
$input-group-hover-light-bg-hover-color: darken($gray-100, 1%) !default;
$input-group-light-bg-color: darken($gray-100, 1%) !default;
$form-select-bg-size: 13px 9px !default;
$form-switch-input-bg-color: $border-color !default;
$form-switch-darkmode-with-icons-input-bg-color: $border-color !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>") !default;
$form-switch-darkmode-with-icons-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>") !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>") !default;
$form-check-select-stretched-input-bg-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$form-check-select-stretched-input-bg-image-color}'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$white}'/></svg>") !default;
$form-check-select-stretched-input-checked-bg-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$primary}'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$white}'/></svg>") !default;
$form-switch-darkmode-with-icons-input-before-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$white}' viewBox='0 0 16 16'><path d='M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z'/></svg>") !default;
$form-switch-darkmode-with-icons-input-after-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$form-switch-darkmode-with-icons-input-after-color}' viewBox='0 0 16 16'><path d='M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z'/><path d='M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z'/></svg>") !default;
$form-check-input-checked-bg-color: $primary !default;
$form-check-select-stretched-bg-color: rgba($primary, .035) !default;
$form-check-select-stretched-btn-bg-active-color: $primary !default;

// Borders
$input-border-color: $border-color !default;
$input-border-width: $border-width !default;
$input-group-text-border-width: 0 !default;
$inpu-card-border-radius: $card-border-radius !default;
$form-check-input-border: 1px solid $input-border-color !default;
$form-switch-input-border-width: 0 !default;
$form-switch-darkmode-with-icons-input-border-width: 0 !default;
$form-check-select-stretched-border-width: $border-width !default;
$form-check-select-stretched-border-color: $border-color !default;
$form-check-select-stretched-border-active-color: $primary !default;
$form-check-select-stretched-btn-border-active-color: $primary !default;

// Allows for customizing button radius independently from global border radius
$input-border-radius: $border-radius-sm !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-sm !default;
$form-select-border-radius: $input-border-radius !default;

// Type
$form-label-font-size: $font-size-base !default;
$form-link-font-weight: $link-font-weight !default;
$form-label-secondary-font-size: $font-size-sm !default;
$form-label-link-font-weight: $font-weight-semi-bold !default;
$form-label-font-size: $font-size-base * .875 !default;
$form-control-single-number-font-size: $font-size-base * 2 !default;
$input-line-height: 1.5 !default;
$input-title-font-size: $font-size-base * 2 !default;
$input-title-line-height: normal !default;
$input-title-font-weight: $font-weight-semi-bold !default;

// Width & Heights
$form-check-input-width: 1rem !default;
$input-group-merge-height-sm: add($input-line-height * 1em, subtract($input-btn-padding-y-sm * 2, $border-width * 2, false)) !default;
$input-group-merge-height-lg: add($input-line-height * 1em, subtract($input-btn-padding-y-lg * 2, $border-width * 2, false)) !default;
$form-switch-input-width: 2.8125em !default;
$form-switch-input-height: 1.8125em !default;
$form-switch-promotion-min-width: 5rem !default;
$form-check-select-stretched-input-pseudo-width: 1.25rem !default;
$form-check-select-stretched-input-pseudo-height: $form-check-select-stretched-input-pseudo-width !default;
$form-switch-darkmode-with-icons-input-width: 4em !default;
$form-switch-darkmode-with-icons-input-height: 2.25em !default;

// Positions
$form-switch-darkmode-with-icons-input-before-left-offset: .5rem !default;
$form-switch-promotion-container-position-top-offset: 1.25rem !default;
$form-switch-promotion-container-position-left-offset: 1.25rem !default;
$form-check-select-stretched-input-pseudo-top-offset: .75rem !default;

// Padding
$form-select-indicator-padding: $input-btn-padding-x * 2.25 !default; // Extra padding for background-image
$form-check-padding-start: $form-check-input-width + .5rem !default;

// Margin
$form-check-margin-y: .125rem !default;
$form-label-margin-bottom: .5rem !default;
$form-text-margin-top: 1rem !default;
$form-link-margin-y: $form-text-margin-top !default;
$form-switch-margin-x: .75rem !default;
$form-switch-darkmode-with-icons-margin-x: .75rem !default;
$form-switch-between-margin-x: .5rem !default;
$form-feedback-margin-top: .25rem !default;
$form-switch-promotion-body-margin-y: 1rem !default;
$form-switch-promotion-arrow-margin-x: 1.75rem !default;
$form-switch-promotion-text-margin-y: .3125rem !default;

// Shadows
$input-focus-width: $input-btn-focus-width !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 $input-btn-focus-blur $form-select-focus-width $input-btn-focus-color !default;

// Transitions
$form-switch-darkmode-with-icons-input-before-transition: $transition-timing !default;

// Others
$form-check-input-active-filter: 100% !default;


// Form Check Label Highlighter
// =========================================================

// Borders
$form-check-label-highlighter-border-active-color: $primary !default;

// Backgrounds
$form-check-label-highlighter-bg-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$gray-300}'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$white}'/></svg>") !default;
$form-check-label-highlighter-checked-bg-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$primary}'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$white}'/></svg>") !default;

// Border
$form-check-label-highlighter-border-width: .125rem !default;
$form-check-label-highlighter-border-color: $input-border-color !default;
$form-check-label-highlighter-border-radius: $border-radius !default;

// Width & Heights
$form-check-label-highlighter-pseudo-width: 1.25rem !default;
$form-check-label-highlighter-pseudo-height: $form-check-select-stretched-input-pseudo-width !default;

// Positions
$form-check-label-highlighter-pseudo-top-offset: .75rem !default;


// Form Check Dashed
// =========================================================

// Backgrounds
$form-check-dashed-bg-hover-color: $gray-100 !default;

// Borders
$form-check-dashed-border-width: .125rem !default;
$form-check-dashed-border-color: $border-color !default;
$form-check-dashed-border-radius: $border-radius !default;

// Padding
$form-check-dashed-padding-y: 2rem !default;
$form-check-dashed-padding-x: $form-check-dashed-padding-y !default;


// Form Check Select
// =========================================================

// Background
$form-check-select-input-bg-color: $gray-300 !default;
$form-check-select-input-hover-bg-color: rgba($primary, .3125) !default;

// Borders
$form-check-select-border-radius: $border-radius-rounded !default;

// Width & Heights
$form-check-select-width: 12px !default;
$form-check-select-height: $form-check-select-width !default;


// Form Check Switch
// =========================================================

// Colors
$form-check-switch-label-color: $primary !default;
$form-check-switch-label-hover-color: $primary !default;
$form-check-switch-label-active-color: $white !default;
$form-check-switch-label-disabled-color: $disabled-color !default;

// Backgrounds
$form-check-switch-label-bg-color: $white-bg !default;
$form-check-switch-label-active-bg-color: $primary !default;

// Borders
$form-check-switch-label-border-width: $border-width !default;
$form-check-switch-label-border-color: $primary !default;
$form-check-switch-label-disabled-bg-color: $disabled-bg-color !default;


// Add Field
// =========================================================

// Colors
$input-group-add-field-delete-color: $danger !default;
$input-group-add-field-delete-hover-color: darken($danger, 7%) !default;

// Positions
$input-group-add-field-top-offset: .75rem !default;
$input-group-add-field-right-offset: 1.25rem !default;

// Margins
$input-group-add-field-margin-y: 1rem !default;


// Buttons
// =========================================================

// Color
$btn-white-hover-color: $link-hover-color !default;
$btn-white-disabled-color: $disabled-color !default;
$btn-group-segment-link-color: $gray-700 !default;
$btn-group-segment-link-active-color: $gray-900 !default;
$btn-group-segment-link-hover-color: $primary !default;

// Backgrounds
$btn-ghost-bg-color: $secondary-bg-color !default;
$btn-white-bg-color: $white !default;
$btn-white-bg-disabled-color: $disabled-bg-color !default;
$btn-group-segment-bg-color: $gray-200 !default;
$btn-group-segment-link-active-bg-color: $white-bg !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $input-border-radius !default;
$btn-border-radius-sm: $input-border-radius-sm !default;
$btn-border-radius-lg: $input-border-radius-lg !default;

// Borders
$btn-outline-secondary-color: $input-border-color !default;
$btn-white-border-color: $btn-outline-secondary-color !default;
$btn-group-segment-border-radius: $border-radius !default;
$btn-group-segment-link-border-radius: $btn-group-segment-border-radius !default;
$btn-group-pills-segment-border-radius: $border-radius-pill !default;
$btn-group-pills-segment-link-border-radius: $btn-group-pills-segment-border-radius !default;

// Type
$btn-link-font-weight: $font-weight-semi-bold !default;

// Shadows
$btn-box-shadow-value: 0 4px 11px !default;
$btn-white-box-shadow-hover: 0px 3px 6px -2px rgba($gray-600, .25) !default;
$btn-group-segment-link-active-box-shadow: $box-shadow-sm !default;

// Padding
$btn-group-segment-padding-y: .25rem !default;
$btn-group-segment-padding-x: $btn-group-segment-padding-y !default;
$btn-group-segment-link-padding-y: $input-btn-padding-y-sm !default;
$btn-group-segment-link-padding-x: $input-btn-padding-x-sm !default;

// Margins
$btn-group-segment-link-margin-x: .25rem !default;


// Button Icon
// =========================================================

// Type
$btn-icon-font-weight: $font-weight-semi-bold !default;
$btn-icon-font-size: $font-size-base !default;
$btn-icon-font-size-xs: $font-size-xs !default;
$btn-icon-font-size-sm: $font-size-base !default;
$btn-icon-font-size-lg: $font-size-lg !default;
$btn-status-font-size: $font-size-xs !default;
$btn-status-line-height: 1 !default;

// Width & Heights
$btn-icon-width: $font-size-base * 3 !default;
$btn-icon-width-xs: $font-size-base * 1.75 !default;
$btn-icon-width-sm: $font-size-base * 2.75 !default;
$btn-icon-width-lg: $font-size-base * 3.85 !default;
$btn-status-width: $font-size-base * 1.3125 !default;

$btn-icon-height: $btn-icon-width !default;
$btn-icon-height-xs: $btn-icon-width-xs !default;
$btn-icon-height-sm: $btn-icon-width-sm !default;
$btn-icon-height-lg: $btn-icon-width-lg !default;
$btn-status-height: $btn-status-width !default;


// Button Status
// =========================================================

// Backgrounds
$btn-status-bg-color: $gray-400 !default;

// Borders
$btn-status-border-radius: $border-radius-rounded !default;
$btn-status-border-radius: $border-radius-rounded !default;
$btn-status-border-width: .125rem !default;
$btn-status-xs-border-width: .1rem !default;
$btn-status-border-color: $white-bg !default;

// Type
$btn-status-line-height: 1 !default;
$btn-status-font-weight: $font-weight-semi-bold !default;
$btn-status-font-size: $font-size-base * .8125 !default;
$btn-status-xs-font-size: $font-size-base * .6125 !default;
$btn-status-sm-font-size: $font-size-base * .75 !default;
$btn-status-lg-font-size: $font-size-base !default;

// Width & Heights
$btn-status-width: 21px !default;
$btn-status-xs-width: 10px !default;
$btn-status-sm-width: 12px !default;
$btn-status-lg-width: 24px !default;

$btn-status-height: $btn-status-width !default;
$btn-status-xs-height: $btn-status-xs-width !default;
$btn-status-sm-height: $btn-status-sm-width !default;
$btn-status-lg-height: $btn-status-lg-width !default;

// Positions
$btn-status-top-offset: .4375rem !default;
$btn-status-right-offset: $btn-status-top-offset !default;
$btn-status-xs-top-offset: .3125rem !default;
$btn-status-xs-right-offset: $btn-status-xs-top-offset !default;
$btn-status-sm-top-offset: .3125rem !default;
$btn-status-sm-right-offset: $btn-status-sm-top-offset !default;
$btn-status-lg-top-offset: .6125rem !default;
$btn-status-lg-right-offset: $btn-status-lg-top-offset !default;


// Icon
// =========================================================

// Type
$icon-font-weight: $btn-icon-font-weight !default;
$icon-font-size: $btn-icon-font-size !default;
$icon-font-size-xs: $btn-icon-font-size-xs !default;
$icon-font-size-sm: $btn-icon-font-size-sm !default;
$icon-font-size-lg: $btn-icon-font-size-lg !default;

// Width & Heights
$icon-width: $btn-icon-width !default;
$icon-width-xs: $btn-icon-width-xs !default;
$icon-width-sm: $btn-icon-width-sm !default;
$icon-width-lg: $btn-icon-width-lg !default;

$icon-height: $icon-width !default;
$icon-height-xs: $icon-width-xs !default;
$icon-height-sm: $icon-width-sm !default;
$icon-height-lg: $icon-width-lg !default;

// Borders
$icon-border-radius: $input-border-radius !default;
$icon-circle-border-radius: $border-radius-rounded !default;


// Avatar
// =========================================================

// Width & Heights
$avatar-width: $btn-icon-width !default;
$avatar-width-xss: 1rem !default;
$avatar-width-xs: $btn-icon-width-xs !default;
$avatar-width-sm: $btn-icon-width-sm !default;
$avatar-width-lg: $btn-icon-width-lg !default;
$avatar-width-xl: $font-size-base * 5.625 !default;
$avatar-width-xxl: $font-size-base * 9 !default;
$avatar-status-width: $btn-status-width !default;
$avatar-status-width-sm: $font-size-base * .9375 !default;
$avatar-status-width-lg: $font-size-base * 1.625 !default;

$avatar-height: $btn-icon-width !default;
$avatar-height-xss: $avatar-width-xss !default;
$avatar-height-xs: $avatar-width-xs !default;
$avatar-height-sm: $avatar-width-sm !default;
$avatar-height-lg: $avatar-width-lg !default;
$avatar-height-xl: $avatar-width-xl !default;
$avatar-height-xxl: $avatar-width-xxl !default;
$avatar-status-height: $avatar-status-width !default;
$avatar-status-height-sm: $avatar-status-width-sm !default;
$avatar-status-height-lg: $avatar-status-width-lg !default;

// Backgrounds
$avatar-bg-color: $white !default;

// Border
$avatar-img-border-radius: $border-radius !default;
$avatar-circle-border-radius: $border-radius-rounded !default;
$avatar-border-width: 2px !default;
$avatar-border-color: $white-bg !default;
$avatar-border-width-lg: 5px !default;
$avatar-border-color-lg: $white-bg !default;
$avatar-border-width-xl: 7px !default;
$avatar-border-color-xl: $white-bg !default;
$avatar-status-border-radius: $avatar-circle-border-radius !default;
$avatar-status-border-width: .125rem !default;
$avatar-status-border-color: $white-bg !default;

// Z-index
$avatar-group-z-index: 2 !default;

// Type
$avatar-font-size-xss: $btn-icon-font-size-xs !default;
$avatar-font-size-xs: $btn-icon-font-size-xs !default;
$avatar-font-size: $btn-icon-font-size !default;
$avatar-font-size-sm: $btn-icon-font-size-sm !default;
$avatar-font-size-lg: $btn-icon-font-size-lg !default;
$avatar-font-size-xl: $font-size-base * 2 !default;
$avatar-font-size-xxl: $font-size-base * 3 !default;
$avatar-font-weight: $btn-icon-font-weight !default;
$avatar-status-font-size: $btn-status-font-size !default;
$avatar-status-line-height: $btn-status-line-height !default;
$avatar-status-font-size-sm: $font-size-base * .5 !default;
$avatar-status-font-size-lg: $font-size-base * .75 !default;

// Margins
$avatar-group-margin-x-xs: -.6125rem !default;
$avatar-group-margin-x: -1rem !default;
$avatar-group-margin-x-sm: -.875rem !default;
$avatar-group-margin-x-lg: -1.5rem !default;
$avatar-group-margin-x-xl: -2rem !default;


// Avatar Uploader
// =========================================================

// Colors
$avatar-uploader-icon-color: $body-color !default;
$avatar-uploader-icon-hover-color: $white !default;

// Backgrounds
$avatar-uploader-input-overlay-bg-color: $overlay-soft-bg-color !default;
$avatar-uploader-icon-bg-color: $white-bg !default;
$avatar-uploader-icon-bg-hover-color: $active-bg-color !default;

// Borders
$avatar-uploader-input-border-radius: $border-radius-rounded !default;
$avatar-uploader-icon-border-radius: $border-radius-rounded !default;

// Type
$avatar-lg-uploader-icon-font-size: $font-size-xs !default;
$avatar-xl-uploader-icon-font-size: $font-size-sm !default;

// Width & Heights
$avatar-lg-uploader-icon-width: $font-size-base * 1.5 !default;
$avatar-lg-uploader-icon-height: $avatar-lg-uploader-icon-width !default;
$avatar-xl-uploader-icon-width: $font-size-base * 1.75 !default;
$avatar-xl-uploader-icon-height: $avatar-xl-uploader-icon-width !default;
$avatar-xxl-uploader-icon-width: $btn-icon-width-sm !default;
$avatar-xxl-uploader-icon-height: $avatar-xxl-uploader-icon-width !default;

// Animations
$avatar-uploader-input-transition: $transition-timing-sm !default;
$avatar-uploader-icon-transition: $transition-timing-sm !default;


// Nav
// =========================================================

// Colors
$nav-link-color: $dark !default;
$nav-link-active-color: $active-color !default;
$nav-link-hover-color: $link-hover-color !default;
$nav-link-disabled-color: $disabled-color !default;
$nav-subtitle-color: $text-muted !default;
$nav-light-link-color: $white-70 !default;
$nav-light-link-hover-color: $white !default;
$nav-tabs-link-active-color: $active-color !default;
$nav-tabs-light-link-active-color: $white !default;
$nav-pills-link-active-color: $nav-link-color !default;
$nav-pills-link-hover-color: $dark !default;
$nav-segment-link-color: $body-color !default;
$nav-segment-link-active-color: $headings-color !default;
$nav-segment-link-active-hover-color: $white !default;
$nav-segment-link-disabled-color: $disabled-color !default;
$nav-segment-link-hover-color: $primary !default;
$nav-subtitle-replacer-color: $text-muted !default;
$nav-compact-icon-color: $body-color !default;
$nav-compact-icon-active-color: $link-hover-color !default;

// Backgrounds
$nav-tabs-link-active-bg: transparent !default;
$nav-pills-link-active-bg: rgba($gray-400, .2) !default;
$nav-pills-link-hover-bg-color: rgba($gray-400, .2) !default;
$nav-segment-bg-color: $gray-200 !default;
$nav-segment-link-active-bg-color: $white !default;
$nav-collapse-pseudo-bg-color: $border-color !default;
$nav-compact-icon-bg-color: $secondary-bg-color !default;
$nav-compact-icon-active-bg-color: $secondary-hover-bg-color !default;

// Width & Heights
$nav-collapse-pseudo-width: .125rem !default;
$nav-collapse-pseudo-height: calc(100% - .25rem) !default;
$nav-compact-link-width: 7rem !default;
$nav-compact-icon-width: $btn-icon-width !default;
$nav-compact-icon-height: $nav-compact-icon-width !default;

// Type
$nav-subtitle-font-size: $font-size-xs !default;
$nav-subtitle-letter-spacing: $letter-spacing !default;
$nav-icon-opacity: .7 !default;
$nav-tabs-font-weight: $font-weight-semi-bold !default;
$nav-subtitle-font-weight: $font-weight-semi-bold !default;
$nav-subtitle-replacer-font-size: $font-size-base * 1.5 !default;
$nav-segment-font-size: $font-size-sm !default;
$nav-sm-link-font-size: .9375rem !default;
$nav-lg-link-font-size: $font-size-base !default;
$nav-compact-link-icon-font-size: 1.25rem !default;
$nav-compact-icon-font-size: $btn-icon-font-size !default;

// Borders
$nav-tabs-border-width: .0625rem !default;
$nav-tabs-link-border-width: .1875rem !default;
$nav-tabs-border-color: $border-color !default;
$nav-tabs-link-hover-border-color: $border-color !default;
$nav-tabs-link-active-border-color: $active-color !default;
$nav-tabs-border-radius: 0 !default;
$nav-segment-border-radius: $border-radius !default;
$nav-break-segment-border-radius: 2rem !default;
$nav-segment-link-border-radius: $nav-segment-border-radius !default;
$nav-pills-segment-border-radius: $border-radius-pill !default;
$nav-pills-segment-link-border-radius: $nav-pills-segment-border-radius !default;
$nav-compact-link-border-radius: $border-radius !default;
$nav-compact-icon-border-radius: $border-radius !default;
$nav-compact-icon-circle-border-radius: $border-radius-rounded !default;

// Shadows
$nav-segment-link-active-box-shadow: 0rem .1875rem .375rem 0rem rgba($gray-600, .25) !default;

// Positions
$nav-collapse-pseudo-left-offset: 1.3125rem !default;

// Padding
$nav-link-padding-x: 1rem !default;
$nav-pills-padding-y: 1rem !default;
$nav-pills-padding-x: $nav-pills-padding-y !default;
$nav-sm-pills-padding-y: .75rem !default;
$nav-sm-pills-padding-x: $nav-sm-pills-padding-y !default;
$nav-lg-pills-padding-y: 1.5rem !default;
$nav-lg-pills-padding-x: $nav-lg-pills-padding-y !default;
$nav-pills-item-margin-y: .25rem !default;
$nav-pills-item-margin-x: .25rem !default;
$nav-tabs-link-padding-y: 1.25rem !default;
$nav-tabs-link-padding-x: 1rem !default;
$nav-segment-padding-y: .25rem !default;
$nav-segment-padding-x: $nav-segment-padding-y !default;
$nav-segment-link-padding-y: $input-btn-padding-y-sm !default;
$nav-segment-link-padding-x: .8125rem !default;
$nav-sm-padding-x: .75rem !default;
$nav-collapse-collapsed-padding-x: 1.9375rem !default;
$nav-subtitle-replacer-padding: .5rem 0 !default;
$nav-vertical-pills-link-padding-x: .875rem !default;

// Margin
$nav-icon-margin-x: 1.9375rem !default;
$nav-vertical-pills-link-margin-y: .25rem !default;
$nav-lg-margin-y: .25rem !default;
$nav-compact-item-margin-y: .75rem !default;
$nav-tabs-link-margin-y: $nav-tabs-link-border-width - .0625rem !default;


// Nav Scroller
// =========================================================

// Colors
$nav-scroller-arrow-link-color: $body-color !default;
$nav-scroller-arrow-link-hover-color: $link-hover-color !default;
$nav-scroller-arrow-light-link-color: $white-70 !default;
$nav-scroller-arrow-light-link-hover-color: $white !default;

// Width & Heights
$nav-scroller-arrow-link-width: $btn-icon-width !default;

// Z-index
$nav-scroller-arrow-z-index: 1 !default;

// Type
$nav-scroller-arrow-font-size: $font-size-base * 1.5 !default;

// Margins
$nav-scroller-arrow-margin-x: .125rem !default;


// Navbar
// =========================================================

// Colors
$navbar-light-brand-color: $body-color !default;
$navbar-light-toggle-color: $gray-600 !default;
$navbar-light-color: $body-color !default;
$navbar-light-active-color: $navbar-light-color !default;
$navbar-light-hover-color: $active-color !default;
$navbar-toggler-color: $body-color !default;
$navbar-toggler-text-color: $dark !default;
$navbar-dark-toggler-text-color: $white !default;
$navbar-dark-aside-toggler-color: $white !default;
$navbar-dark-hover-color: $white !default;
$navbar-dark-divider-color: rgba($white, .2) !default;
$navbar-dark-nav-link-color: $white !default;
$navbar-dropdown-menu-media-title-color: $gray-900 !default;
$navbar-dropdown-menu-media-desc-color: $gray-700 !default;
$navbar-dropdown-menu-media-title-hover-color: $link-color !default;
$navbar-dropdown-menu-promo-link-title-active-color: $navbar-dropdown-menu-media-title-hover-color !default;
$navbar-dropdown-account-wrapper-color: $headings-color !default;
$navbar-vertical-nav-collapse-link-pseudo-color: $gray-500 !default;

// Backgrounds
$navbar-bg-color: $white-bg !default;
$navbar-dark-bg-color: $dark !default;
$navbar-dark-aside-toggler-bg-color: $dark !default;
$navbar-dark-compact-icon-bg-color: rgba($white, .1) !default;
$navbar-toggle-bg-hover-color: darken($gray-200, 2%) !default;
$navbar-light-scrolled-bg-color: $white !default;
$navbar-dark-scrolled-bg-color: $dark !default;
$navbar-dropdown-menu-media-link-bg-hover-color: rgba($gray-400, .2) !default;
$navbar-dropdown-menu-promo-link-bg-active-color: $navbar-dropdown-menu-media-link-bg-hover-color !default;
$navbar-dropdown-menu-shop-banner-bg-color: $gray-100 !default;
$navbar-floating-section-bg-color: $white !default;
$navbar-dropdown-account-wrapper-bg-color: transparent !default;
$navbar-dropdown-account-wrapper-bg-hover-color: $secondary-hover-bg-color !default;
$navbar-dropdown-pseudo-bg-color: $border-color !default;
$navbar-dark-input-group-hover-light-bg-color: rgba($white, .1) !default;
$navbar-dark-input-group-hover-light-bg-focus-color: $white !default;

// Borders
$navbar-border-width: $border-width !default;
$navbar-border-color: $border-color !default;
$navbar-dark-vertical-footer-border-color: rgba($white, .1) !default;
$navbar-dark-aside-toggler-border-color: rgba($white, .1) !default;
$navbar-dropdown-menu-link-border-radius: $border-radius !default;
$navbar-vertical-nav-tabs-border-color: transparent !default;
$navbar-light-toggle-border-color: $border-color !default;
$navbar-dropdown-menu-border-width: .1875rem !default;
$navbar-dropdown-menu-border-color: $primary !default;
$navbar-collapsed-dropdown-menu-border-color: $gray-300 !default;
$navbar-dropdown-menu-promo-item-border-width: $border-width !default;
$navbar-dropdown-menu-promo-item-border-color: $border-color !default;
$navbar-dropdown-menu-banner-border-radius: $border-radius !default;
$navbar-floating-border-radius: $border-radius !default;
$navbar-sidebar-aside-brand-wrapper-border-width: $border-width !default;
$navbar-sidebar-aside-brand-wrapper-border-color: $border-color !default;
$navbar-dropdown-account-wrapper-border-radius: $border-radius-pill !default;

// Width & Heights
$navbar-height: 3.875rem !default;
$navbar-brand-default-width: 6.5rem !default;
$navbar-brand-width: 16.25rem !default;
$navbar-brand-short-width: 2rem !default;
$navbar-absolute-height: 75vh !default;
$navbar-sticky-height: 75vh !default;
$navbar-sidebar-aside-width: 17rem !default;
$navbar-sidebar-aside-brand-height: 5rem !default;
$navbar-dropdown-menu-shop-banner-img-width: 10rem !default;
$navbar-floating-height: $navbar-absolute-height !default;
$navbar-input-group-min-width: 21rem !default;
$navbar-dropdown-pseudo-width: .125rem !default;
$navbar-dropdown-pseudo-height: calc(100% - .25rem) !default;

// Type
$navbar-topbar-toggler-font-size: $font-size-sm !default;
$navbar-dropdown-menu-media-title-font-weight: $font-weight-semi-bold !default;
$navbar-dropdown-menu-media-desc-font-size: $font-size-base * .875 !default;

// Z-index
$navbar-z-index: 99 !default;
$navbar-topbar-z-index: 11 !default;
$navbar-sidebar-aside-z-index: $navbar-z-index !default;
$navbar-vertical-aside-toggler-z-index: 1000 !default;

// Shadows
$navbar-box-shadow: $box-shadow !default;
$navbar-light-scrolled-box-shadow: $box-shadow !default;

// Positions
$navbar-dropdown-menu-pseudo-top-offset: .625rem !default;
$navbar-left-mini-offset: 5.75rem !default;
$navbar-dropdown-sub-menu-right-offset: calc(100% + 1rem) !default;
$navbar-dropdown-pseudo-left-offset: .4375rem !default;

// Padding
$navbar-container-padding: 2rem !default; // Main layout size
$navbar-toggler-padding-y: .5rem !default;
$navbar-toggler-padding-x: $navbar-toggler-padding-y !default;
$navbar-nav-padding-y: 1rem !default;
$navbar-nav-padding-x: $navbar-nav-padding-y !default;
$navbar-nav-link-padding-y: 1rem !default;
$navbar-nav-link-padding-x: .75rem !default;
$navbar-btn-padding-y: .35rem !default;
$navbar-btn-padding-x: .5rem !default;
$navbar-vertical-aside-nav-pills-x: 1.25rem !default;
$navbar-vertical-nav-link-padding-y: .3125rem !default;
$navbar-vertical-nav-link-padding-x: $nav-link-padding-x * 1.5 !default;
$navbar-vertical-nav-collapse-link-padding-x: $navbar-vertical-nav-link-padding-x * 2.6125 !default;
$navbar-vertical-nav-collapse-2nd-level-link-padding-x: $navbar-vertical-nav-collapse-link-padding-x + .75rem !default;
$navbar-dropdown-menu-inner-padding-y: .75rem !default;
$navbar-dropdown-menu-inner-padding-x: $navbar-dropdown-menu-inner-padding-y !default;
$navbar-dropdown-menu-media-link-padding-y: 1rem !default;
$navbar-dropdown-menu-media-link-padding-x: $navbar-dropdown-menu-media-link-padding-y !default;
$navbar-dropdown-menu-promo-link-padding-y: $navbar-dropdown-menu-media-link-padding-y !default;
$navbar-dropdown-menu-promo-link-padding-x: $navbar-dropdown-menu-promo-link-padding-y !default;
$navbar-dropdown-menu-banner-content-padding-y: 2rem !default;
$navbar-dropdown-menu-banner-content-padding-x: 2rem !default;
$navbar-dropdown-collapsed-padding-x: 1rem !default;
$navbar-floating-nav-padding-x: 1rem !default;
$navbar-sidebar-aside-content-padding-x: .75rem !default;
$navbar-sidebar-aside-brand-padding-y: 1.25rem !default;
$navbar-sidebar-aside-brand-padding-x: 1.5rem !default;
$navbar-dropdown-account-wrapper-padding: .25rem !default;
$navbar-nav-wrap-content-start-padding-x: 2rem !default;
$navbar-vertical-nav-collapse-link-pseudo-padding-x: .5rem !default;
$navbar-card-list-group-item-padding-y: 1rem !default;

// Margins
$navbar-topbar-margin-y: .4375rem !default;
$navbar-dropdown-margin-y: .75rem !default;
$navbar-dropdown-menu-link-margin-y: .5rem !default;
$navbar-nav-item-margin-x: .5rem !default;
$navbar-dropdown-menu-promo-item-margin-y: $navbar-dropdown-menu-inner-padding-y !default;
$navbar-dropdown-menu-promo-item-margin-x: $navbar-dropdown-menu-promo-item-margin-y !default;
$navbar-dropdown-menu-inner-menu-margin-y: .6875rem !default;
$navbar-dropdown-menu-inner-menu-margin-x: .5rem !default;
$navbar-floating-margin-y: 2.5rem !default;
$navbar-floating-dropdown-menu-margin-y: .5rem !default;
$navbar-dropdown-account-wrapper-margin-x: .5rem !default;
$navbar-card-list-group-margin: .5rem 0 .5rem .5rem !default;

// Gap
$navbar-nav-wrap-secondary-content-gap: .5rem !default;

// Others
$navbar-fix-effect-transition: $transition-timing ease !default;
$navbar-dropdown-account-wrapper-transition: $transition-timing !default;
$navbar-vertical-nav-collapse-link-pseudo-content: '\2022' !default;


// Navbar Vertical Aside
// =========================================================

// Colors
$navbar-vertical-aside-toggler-color: $body-color !default;

// Backgrounds
$navbar-vertical-aside-toggler-bg-color: $white-bg !default;
$navbar-vertical-mobile-overlay-bg-color: $overlay-soft-bg-color !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-bg-color: $gray-400 !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-bg-active-color: $primary !default;
$navbar-vertica-aside-min-mode-nav-collapse-bg-color: $white-bg !default;
$navbar-vertical-aside-compact-menu-bg-color: $white-bg !default;

// Border
$navbar-vertical-aside-toggler-border-width: $border-width !default;
$navbar-vertical-aside-toggler-border-color: $border-color !default;
$navbar-vertical-aside-toggler-border-radius-rounded: $border-radius-rounded !default;
$navbar-vertical-footer-border-width: $border-width !default;
$navbar-vertical-footer-border-color: $border-color !default;
$navbar-vertical-menu-nav-link-border-width: .25rem !default;
$navbar-vertical-menu-nav-link-border-active-color: $active-color !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-border-radius: $border-radius-rounded !default;
$navbar-vertica-aside-min-mode-nav-collapse-border-radius: $border-radius-sm !default;
$navbar-vertical-aside-compact-menu-border-radius: $border-radius !default;

// Z-Index
$navbar-vertical-z-index: $navbar-z-index + 1 !default;
$navbar-vertical-mobile-overlay-z-index: $navbar-vertical-z-index - 1 !default;

// Width & Height
$navbar-vertical-width: $navbar-brand-width !default;
$navbar-vertical-compact-width: 9rem !default;
$navbar-vertical-compact-title-width: 8.5rem !default;
$navbar-vertical-mini-width: $navbar-left-mini-offset !default;
$navbar-vertical-brand-height: $navbar-height !default;
$navbar-vertical-brand-logo-mini-width: 1.9125rem !default;
$navbar-vertical-aside-toggler-btn-icon-width: $btn-icon-width-sm !default;
$navbar-vertical-aside-toggler-btn-icon-height: $navbar-vertical-aside-toggler-btn-icon-width !default;
$navbar-vertical-menu-pseudo-width: 1.75rem !default;
$navbar-vertical-detached-width: $navbar-brand-width !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-width: 5px !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-height: $navbar-vertical-aside-link-dropdown-toggle-pseudo-width !default;
$navbar-vertica-aside-min-mode-nav-collapse-min-width: 15rem !default;
$navbar-vertica-aside-min-mode-nav-collapse-pseudo-width: 1.125rem !default;
$navbar-vertica-aside-min-mode-nav-collapse-pseudo-height: calc(100% - .25rem) !default;
$navbar-vertical-aside-compact-menu-min-width: 15rem !default;

// Type
$navbar-vertical-aside-toggler-btn-icon-font-size: $btn-icon-font-size-sm !default;

// Positions
$navbar-vertical-aside-compact-mini-has-menu-pseudo-right-offset: .5rem !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-left-offset: .6125rem !default;
$navbar-vertica-aside-min-mode-nav-collapse-left-offset: calc(100% + .9125rem) !default;
$navbar-vertica-aside-min-mode-nav-collapse-pseudo-left-offset: 1.0625rem !default;
$navbar-vertical-aside-compact-menu-left-offset: calc(100% + .9125rem) !default;

// Margins
$navbar-vertical-margin-left: -$navbar-vertical-width !default;
$navbar-vertical-footer-dropdown-margin-y: 1.5rem !default;
$navbar-footer-item-margin-y: 2rem !default;

// Padding
$navbar-vertical-brand-padding-x: 1.75rem !default;
$navbar-vertical-footer-padding-x: $navbar-container-padding !default;
$navbar-vertical-footer-padding-y: .75rem !default;
$navbar-vertical-footer-bottom-offset: 4.25rem !default;
$navbar-vertical-menu-nav-link-padding-x: $navbar-vertical-brand-padding-x + .25rem !default;
$navbar-vertical-footer-list-item-margin-x: .5rem !default;
$navbar-vertical-footer-list-item-margin-y: $navbar-vertical-footer-list-item-margin-x !default;

// Positions
$navbar-vertical-menu-pseudo-right-offset: calc(100% - .0625rem) !default;
$navbar-vertical-footer-dropdown-left-offset: calc(100% + 1rem) !default;

// Shadows
$navbar-vertical-aside-compact-menu-box-shadow: $box-shadow-lg !default;

// Animations
$navbar-vertical-transition: $transition-timing-sm $transition-function !default;
$navbar-vertical-mobile-overlay-transition: opacity $transition-timing-sm ease !default;
$navbar-vertical-aside-link-dropdown-toggle-pseudo-transition: opacity $transition-timing !default;


// Drop Down
// =========================================================

// Colors
$dropdown-color: $body-color !default;
$dropdown-header-color: $card-subtitle-color !default;
$dropdown-link-active-color: $gray-900 !default;
$dropdown-icon-color: $body-color !default;

// Backgrounds
$dropdown-bg: $white-bg !default;
$dropdown-link-hover-bg: $nav-pills-link-active-bg !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;
$dropdown-overlay-bg-color: $overlay-soft-bg-color !default;
$dropdown-divider-bg: $border-color !default;

// Width & Heights
$dropdown-icon-width: 1.5rem !default;
$dropdown-toggle-pseudo-width: 1rem !default;
$dropdown-toggle-pseudo-height: 1rem !default;
$navbar-dropdown-menu-pseudo-height: 1.75rem !default;

// Type
$dropdown-item-font-size: $font-size-sm !default;
$dropdown-header-font-size: $nav-subtitle-font-size !default;
$dropdown-header-font-weight: $font-weight-semi-bold !default;
$dropdown-toggle-pseudo-bg: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$dropdown-color}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;
$dropdown-toggle-pseudo-light-bg: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{rgba($white, .55)}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;

// Borders
$dropdown-border-width: 0 !default;
$dropdown-item-border-radius: $border-radius-sm !default;

// Z-index
$dropdown-overlay-z-index: $navbar-z-index + 1 !default;

// Padding
$dropdown-padding-x: .5rem !default;
$dropdown-padding-y: .5rem !default;
$dropdown-item-padding-y: .5rem !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-header-padding: .5rem 1rem !default;
$dropdown-toggle-padding-x: 1.25rem !default;

// Margin
$dropdown-spacer: .625rem !default;
$dropdown-item-margin-y: .25rem !default;
$dropdown-margin-y: .3125rem !default;

// Shadows
$dropdown-box-shadow: $box-shadow-lg !default;

// Transition
$dropdown-overlay-transition: $transition-timing-sm ease !default;
$dropdown-toggle-psuedo-transition: $transition-timing !default;
$dropdown-toggle-psuedo-collapse-rotation: rotate(-180deg) !default;

// Others
$dropdown-icon-opacity: .7 !default;


// Content
// =========================================================

// Padding
$content-space-padding: $navbar-container-padding !default;
$content-padding-y: 1.75rem !default;
$content-padding-x: $navbar-container-padding !default;
$content-padding-left: $navbar-container-padding !default;

// Width & Heights
$kanban-board-max-width: 350px !default;


// Splitted Content
// =========================================================

// Backgrounds
$splitted-content-bg-color: $white-bg !default;

// Borders
$splitted-content-col-border-width: $border-width !default;
$splitted-content-col-border-color: $border-color !default;

// Width & Height
$splitted-content-mini-width: $navbar-left-mini-offset !default;
$splitted-content-small-width: 20rem !default;

// Z-index
$splitted-content-mini-offcanvas-z-index: 1041 !default;


// Footer
// =========================================================

// Width & Heights
$footer-height: 3.5rem !default;

// Padding
$footer-padding-x: $navbar-container-padding !default;


// Accordion
// =========================================================

// Colors
$accordion-button-color: $dark !default;
$accordion-icon-color: $dark !default;
$accordion-icon-active-color: $active-color !default;

// Backgrounds
$accordion-button-active-bg: $body-bg !default;
$accordion-border-color: $border-color !default;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/></svg>") !default;

// Borders
$accordion-border-color: $border-color !default;

// Width & Heights
$accordion-icon-width: 1rem !default;

// Type
$accordion-button-font-size: $font-size-base !default;
$accordion-button-font-weight: $font-weight-semi-bold !default;

// Padding
$accordion-padding-y: .75rem !default;
$accordion-padding-x: 1.25rem !default;

// Transition
$accordion-icon-transform: rotate(0deg) !default;


// Alert
// =========================================================

// Padding
$alert-padding-y: .75rem !default;
$alert-padding-x: .75rem !default;


// Badge
// =========================================================

// Borders
$badge-border-radius: $border-radius-sm !default;

// Margins
$badge-legend-indicator-margin-x: .3125rem !default;


// Text
// =========================================================

// Colors
$text-cap-color: $headings-color !default;

// Type
$text-cap-font-size: $card-subtitle-font-size !default;
$text-cap-font-weight: $font-weight-semi-bold !default;
$text-cap-letter-spacing: $letter-spacing !default;

// Margin
$text-cap-margin-y: 1rem !default;


// Blockquote
// =========================================================

// Color
$blockquote-color: $body-color !default;

// Type
$blockquote-font-size: 1.25rem !default;
$blockquote-sm-font-size: $font-size-base !default;
$blockquote-border-left-width: .1875rem !default;
$blockquote-border-left-color: $border-color !default;

// Padding
$blockquote-padding-x: .75rem !default;
$blockquote-sm-padding-x: .6125rem !default;

// Margin
$blockquote-footer-margin-y: 1.5rem !default;


// Lists
// =========================================================

// Color
$list-separator-link-color: $body-color !default;
$list-separator-link-hover-color: $link-hover-color !default;
$list-separator-light-link-color: $white !default;
$list-group-icon-color: $dropdown-icon-color !default;

// Backgrounds
$list-group-active-bg: $primary !default;
$list-group-striped-bg-color: rgba($primary, .1) !default;
$list-group-item-bg-scale: 0% !default;

// Borders
$list-group-border-color: $gray-300 !default;
$list-group-start-bordered-width: .125rem !default;
$list-group-start-bordered-color: transparent !default;
$list-comment-item-border-left-width: .1875rem !default;
$list-comment-item-border-left-color: $border-color !default;
$list-comment-divider-item-border-width: .0625rem !default;
$list-comment-divider-item-border-color: $border-color !default;

// Width & Heights
$list-group-icon-width: 2rem !default;
$list-group-lg-icon-width: 2.5rem !default;

// Type
$list-group-sm-item-font-size: $font-size-sm !default;
$list-group-lg-icon-font-size: $font-size-base * 1.5 !default;

// Padding
$list-group-item-padding-y: 1rem !default;
$list-group-item-padding-x: 1rem !default;
$list-group-lg-item-padding-y: 1.5rem !default;
$list-group-lg-item-padding-x: 1.5rem !default;
$list-separator-padding-x: 2rem !default;
$list-comment-item-padding-x: 1rem !default;

// Margin
$list-group-icon-margin-x: .5rem !default;
$list-group-lg-icon-margin-x: .5rem !default;
$list-separator-item-margin-x: .25rem !default;
$list-comment-margin-y: 2.5rem !default;

// Others
$list-separator-pseudo-content: '/' !default;
$list-separator-pseudo-opacity: .4 !default;


// List Divider
// =========================================================

// Borders
$list-divider-border-width: .125rem !default;
$list-divider-border-color: $border-color !default;

// Padding
$list-divider-item-padding-y: 3rem !default;
$list-divider-item-padding-x: $list-divider-item-padding-y !default;

// Grid
$list-divider-grid-columns: 50% 50% !default;


// List Checked
// =========================================================

// Colors
$list-checked-item-color: $body-color !default;
$list-checked-item-hover-color: $link-color !default;

// Width & Heights
$list-checked-pseudo-width: 1.25rem !default;
$list-checked-pseudo-height: 1.25rem !default;
$list-checked-sm-pseudo-width: 1rem !default;
$list-checked-sm-pseudo-height: 1rem !default;
$list-checked-lg-pseudo-width: 1.75rem !default;
$list-checked-lg-pseudo-height: 1.75rem !default;

// Others
$list-checked-pseudo-soft-bg-opacity-value: .1 !default;

// Type
$list-checked-pseudo: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$form-select-indicator-color}'/></svg>") !default;

// Padding
$list-checked-item-padding-x: $list-checked-pseudo-width + .75rem !default;
$list-checked-sm-item-padding-x: $list-checked-sm-pseudo-width + .5rem !default;
$list-checked-lg-item-padding-x: $list-checked-lg-pseudo-width + 1rem !default;

// Margins
$list-checked-item-margin-y: .5rem !default;
$list-checked-lg-item-margin-y: 1.25rem !default;
$list-checked-pseudo-margin-y: .125rem !default;
$list-checked-bg-pseudo-margin-y: .25rem !default;
$list-checked-sm-pseudo-margin-y: .3125rem !default;
$list-checked-sm-bg-pseudo-margin-y: .25rem !default;
$list-checked-lg-pseudo-margin-y: 0 !default;
$list-checked-lg-bg-pseudo-margin-y: .0625rem !default;


// List Pointer
// =========================================================

// Colors
$list-pointer-item-color: $body-color !default;
$list-pointer-item-hover-color: $link-color !default;

// Width & Heights
$list-pointer-pseudo-width: 1.25rem !default;
$list-pointer-pseudo-height: 1.25rem !default;
$list-pointer-sm-pseudo-width: 1rem !default;
$list-pointer-sm-pseudo-height: 1rem !default;
$list-pointer-lg-pseudo-width: 1.75rem !default;
$list-pointer-lg-pseudo-height: 1.75rem !default;

// Others
$list-pointer-pseudo-soft-bg-opacity-value: .1 !default;

// Type
$list-pointer-pseudo: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-select-indicator-color}' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/></svg>") !default;

// Padding
$list-pointer-item-padding-x: $list-pointer-pseudo-width + .5rem !default;
$list-pointer-sm-item-padding-x: $list-pointer-sm-pseudo-width + .5rem !default;
$list-pointer-lg-item-padding-x: $list-pointer-lg-pseudo-width + 1rem !default;

// Margins
$list-pointer-item-margin-y: .5rem !default;
$list-pointer-lg-item-margin-y: 1.25rem !default;
$list-pointer-pseudo-margin-y: .1875rem !default;
$list-pointer-bg-pseudo-margin-y: .25rem !default;
$list-pointer-sm-pseudo-margin-y: .3125rem !default;
$list-pointer-sm-bg-pseudo-margin-y: .25rem !default;
$list-pointer-lg-pseudo-margin-y: 0 !default;
$list-pointer-lg-bg-pseudo-margin-y: .0625rem !default;


// Modals
// =========================================================

// Backgrounds
$modal-backdrop-bg: $overlay-soft-bg-color !default;

// Borders
$modal-content-border-color: transparent !default;
$modal-header-border-width: 0 !default;
$modal-footer-border-width: $border-width !default;

// Padding
$modal-inner-padding: 2rem !default;
$modal-close-padding-y: .25rem !default;
$modal-close-padding-x: $modal-close-padding-y !default;

// Width & Heights
$modal-md: 550px !default;
$modal-sm: 400px !default;
$modal-top-cover-min-height: 8rem !default;

// Z-index
$zindex-modal-backdrop: $navbar-z-index + 1 !default;
$zindex-modal: $zindex-modal-backdrop + 1 !default;

// Position Spaces
$modal-top-cover-close-top-offset: .75rem !default;
$modal-top-cover-close-right-offset: $modal-top-cover-close-top-offset !default;

// Type
$modal-footer-text-font-size: .875rem !default;

// Margins
$modal-top-cover-avatar-margin-y: -3rem !default;

// Others
$modal-backdrop-opacity: 1 !default;
$modal-top-cover-close-z-index: 2 !default;


// Close
// =========================================================

// Color
$btn-close-light-color: $white !default;

// Type
$btn-close-light-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-light-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;


// Offcanvas
// =========================================================

// Borders
$offcanvas-header-border-bottom-color: $border-color !default;
$offcanvas-footer-border-bottom-color: $border-color !default;

// Padding
$offcanvas-header-padding-y: 1.5rem !default;
$offcanvas-footer-padding-y: $offcanvas-header-padding-y !default;


// Step
// =========================================================

// Colors
$step-title-color: $headings-color !default;
$step-text-color: $body-color !default;
$step-active-icon-color: $white !default;
$step-is-valid-color: $primary !default;
$step-is-valid-icon-color: $white !default;
$step-is-invalid-color: $danger !default;
$step-is-invalid-icon-color: $white !default;

// Background
$step-icon-pseudo-bg-color: $gray-500 !default;
$step-active-icon-bg-color: $active-bg-color !default;
$step-is-valid-icon-bg-color: $step-is-valid-color !default;
$step-is-invalid-icon-bg-color: $step-is-invalid-color !default;

// Borders
$step-border-width: .125rem !default;
$step-border-color: $border-color !default;
$step-icon-border-radius: $border-radius-rounded !default;
$step-icon-pseudo-border-radius: $border-radius-rounded !default;
$step-avatar-border-radius: $step-icon-border-radius !default;

// Width & Height
$step-icon-width: $btn-icon-width !default;
$step-icon-height: $step-icon-width !default;
$step-icon-width-xs: $btn-icon-width-xs !default;
$step-icon-height-xs: $step-icon-width-xs !default;
$step-icon-width-sm: $btn-icon-width-sm !default;
$step-icon-height-sm: $step-icon-width-sm !default;
$step-icon-width-lg: $font-size-base * 5.5 !default;
$step-icon-height-lg: $step-icon-width-lg !default;

$step-avatar-width: $step-icon-width !default;
$step-avatar-height: $step-avatar-width !default;
$step-avatar-width-xs: $step-icon-width-xs !default;
$step-avatar-height-xs: $step-avatar-width-xs !default;
$step-avatar-width-sm: $step-icon-width-sm !default;
$step-avatar-height-sm: $step-avatar-width-sm !default;
$step-avatar-width-lg: $step-icon-width-lg !default;
$step-avatar-height-lg: $step-avatar-width-lg !default;

$step-icon-pseudo-width: .25rem !default;
$step-icon-pseudo-height: $step-icon-pseudo-width !default;

$step-divider-height: 1rem !default;

// Type
$step-icon-font-size: $btn-icon-font-size !default;
$step-icon-font-weight: $font-weight-semi-bold !default;

$step-icon-font-size-xs: $btn-icon-font-size-xs !default;
$step-icon-font-size-sm: $btn-icon-font-size-sm !default;
$step-icon-font-size-lg: $btn-icon-font-size-lg !default;

$step-title-font-weight: $font-weight-semi-bold !default;

$step-avatar-font-weight: $step-icon-font-weight !default;
$step-avatar-font-size: $step-icon-font-size !default;
$step-avatar-font-size-xs: $step-icon-font-size-xs !default;
$step-avatar-font-size-sm: $step-icon-font-size-sm !default;
$step-avatar-font-size-lg: $step-icon-font-size-lg !default;

$step-divider-font-size: $font-size-base * .8125 !default;
$step-divider-font-weight: $font-weight-semi-bold !default;

// Padding
$step-padding-x: 1.5rem !default;
$step-padding-y: $step-padding-x !default;

// Margins
$step-item-margin-y: 2.25rem !default;
$step-icon-margin-y: 1rem !default;
$step-icon-margin-x: 1rem !default;
$step-avatar-margin-x: $step-icon-margin-x !default;
$step-timeline-content-margin-x: 1.5rem !default;
$step-timeline-icon-margin-x: $step-icon-width / 2 !default;
$step-timeline-icon-xs-margin-x: $step-icon-width-xs / 2 !default;
$step-timeline-icon-sm-margin-x: $step-icon-width-sm / 2 !default;
$step-timeline-icon-lg-margin-x: $step-icon-width-lg / 2 !default;


// SVG Icon
// =========================================================

// Width & Heights
$svg-icon-width: 2.75rem !default;
$svg-icon-height: $svg-icon-width !default;
$svg-icon-xs-width: 1.25rem !default;
$svg-icon-xs-height: $svg-icon-xs-width !default;
$svg-icon-sm-width: 1.75rem !default;
$svg-icon-sm-height: $svg-icon-sm-width !default;
$svg-icon-lg-width: 3.5rem !default;
$svg-icon-lg-height: $svg-icon-lg-width !default;

// Margins
$svg-icon-margin-y: .125rem !default;


// Breadcrumbs
// =========================================================

// Colors
$breadcrumb-item-color: $headings-color !default;
$breadcrumb-link-color: $text-muted !default;
$breadcrumb-link-hover-color: $link-hover-color !default;

// Backgrounds
$breadcrumb-bg: transparent !default;

// Borders
$breadcrumb-divider-color: $gray-500 !default;


// Page Header
// =========================================================

// Colors
$page-header-light-title-color: $white !default;
$page-header-light-text-color: $white-70 !default;

// Borders
$page-header-border-width: $border-width !default;
$page-header-border-color: $border-color !default;
$page-header-light-border-color: rgba($white, .1) !default;

// Padding
$page-header-padding-y: 2rem !default;

// Margins
$page-header-margin-y: 2.25rem !default;
$page-header-title-margin-y: .5rem !default;
$page-header-text-margin-y: .25rem !default;
$page-header-breadcrumb-margin-y: .25rem !default;


// Page Preloader
// =========================================================

// Background
$page-preloader-bg-color: $white !default;

// Others
$page-preloader-z-index: $navbar-z-index + 1 !default;


// Pagination
// =========================================================

// Colors
$pagination-color: $gray-700 !default;

// Borders
$pagination-border-width: 0 !default;
$pagination-border-radius: $border-radius-sm !default;

// Width & Heights
$pagination-link-min-width: 2.25rem !default;

// Padding
$pagination-padding-x-lg: 1.25rem !default;

// Margin
$pagination-item-margin-x: .25rem !default;


// Popover
// =========================================================

// Color
$popover-dark-header-color: $white !default;
$popover-dark-body-color: $gray-400 !default;

// Background
$popover-bg: $body-bg !default;
$popover-header-bg: $gray-200 !default;
$popover-arrow-color: $popover-bg !default;
$popover-dark-header-bg-color: $dark !default;
$popover-dark-bg-color: $dark !default;

// Border
$popover-border-color: transparent !default;
$popover-arrow-color: $white !default;
$popover-arrow-outer-color: $popover-border-color !default;
$popover-dark-header-border-color: rgba($white, .1) !default;
$popover-dark-arrow-color: $popover-dark-bg-color !default;

// Type
$popover-font-size: $font-size-base !default;
$popover-header-font-weight: $font-weight-semi-bold !default;

// Shadow
$popover-box-shadow: $box-shadow-lg !default;

// Padding
$popover-body-padding-y: 1.5rem !default;
$popover-body-padding-x: $popover-body-padding-y !default;
$popover-header-padding-x: $popover-body-padding-x !default;
$popover-header-padding-y: 1rem !default;


// Profile
// =========================================================

// Backgrounds
$profile-cover-bg-color: $gray-300 !default;
$profile-cover-avatar-bg-color: $white !default;

// Borders
$profile-cover-border-radius: $card-border-radius !default;
$profile-cover-border-width: .25rem !default;
$profile-cover-border-color: $body-bg !default;

// Width & Heights
$profile-cover-height: 7.5rem !default;
$profile-cover-lg-height: 10rem !default;

// Padding
$profile-cover-content-padding-y: 1rem !default;
$profile-cover-content-padding-x: 1rem !default;
$profile-cover-avatar-padding-y: .25rem !default;
$profile-cover-avatar-padding-x: $profile-cover-avatar-padding-y !default;

// Margins
$profile-cover-padding-y: 1rem !default;
$profile-cover-padding-x: 1rem !default;
$profile-cover-avatar-margin-y: 1.75rem !default;


// Progress
// =========================================================

// Backgrounds
$progress-bg: $gray-400 !default;
$progress-vertical-bg-color: $progress-bg !default;

// Width & Heights
$progress-height: .5rem !default;
$progress-vertical-width: .5rem !default;
$progress-vertical-height: 12.5rem !default;


// Sliding Image
// =========================================================

// Width & Heights
$sliding-img-frame-width: 5760px * 1 / 2 !default;
$sliding-img-frame-height: 562px * 1 / 2 !default;

// Backgrounds
$sliding-img-frame-bg-size: $sliding-img-frame-width $sliding-img-frame-height !default;

// Animations
$sliding-img-frame-to-start-animation: sliding-img-frame-to-start 60s linear infinite !default;
$sliding-img-frame-to-end-animation: sliding-img-frame-to-end 60s linear infinite !default;


// Legend Indicator
// =========================================================

// Backgrounds
$legend-indicator-bg-color: $progress-bg !default;

// Borders
$legend-indicator-border-radius: $border-radius-rounded !default;

// Width & Heights
$legend-indicator-width: .5rem !default;
$legend-indicator-height: $legend-indicator-width !default;

// Margins
$legend-indicator-margin-y: .5rem !default;
$legend-indicator-margin-x: .4375rem !default;


// Table
// =========================================================

// Colors
$table-thead-th-color: $body-color !default;
$table-body-color: $body-color !default;

// Background
$table-thead-light-bg: $gray-200 !default;
$table-striped-bg: $gray-100 !default;
$table-hover-bg: rgba($gray-300, .4) !default;

// Borders
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;
$table-thead-border-color: $table-border-color !default;
$table-group-separator-color: $gray-300 !default;

// Width & Heights
$table-progress-min-width: 5rem !default;
$table-progress-height: .25rem !default;
$table-input-group-min-width: 14rem !default;

// Type
$table-th-font-size: $font-size-base * .85 !default;
$table-thead-th-font-weight: $font-weight-semi-bold !default;
$table-sm-font-size: $font-size-sm !default;

// Padding
$table-cell-padding-y: .75rem !default;
$table-cell-padding-x: .75rem !default;
$table-cell-padding-y-sm: .5rem !default;
$table-cell-padding-x-sm: .5rem !default;
$table-cell-padding-y-lg: 1rem !default;
$table-cell-padding-x-lg: 1.5rem !default;


// Toast State
// =========================================================

// Borders
$toast-border-color: $border-color !default;
$toast-border-radius: $card-border-radius !default;
$toast-header-color: $text-muted !default;

// Backgrounds
$toast-background-color: rgba($white-bg, .85) !default;
$toast-header-background-color: rgba($white-bg, .85) !default;

// Shadow
$toast-box-shadow: $box-shadow-sm !default;

// Padding
$toast-padding-y: 1rem !default;
$toast-padding-x: $toast-padding-y !default;


// Tooltips
// =========================================================

// Backgrounds
$tooltip-bg: $dark !default;

// Others
$tooltip-opacity: 1 !default;


// Text Highlight
// =========================================================

$text-highlight-bg-value: left 1em/1em .2em !default;


// Column Divider
// =========================================================

// Borders
$col-divider-width: $border-width !default;
$col-divider-bg-color: $border-color !default;
$col-divider-light-bg-color: rgba($white, .1) !default;

// Padding
$col-divider-padding-y: 1.5rem !default;


// Divider
// =========================================================

// Colors
$divider-color: $text-muted !default;

// Borders
$divider-border-width: $border-width !default;
$divider-border-color: $border-color !default;

// Margins
$divider-margin-x: 1.5rem !default;


// Devices
// =========================================================

// Colors
$device-browser-header-browser-bar-color: $body-color !default;

// Backgrounds
$device-browser-bg-color: $white-bg !default;
$device-browser-header-bg-color: $white-bg !default;
$device-browser-header-btn-list-btn-bg-color: $gray-300 !default;
$device-browser-header-browser-bar-bg-color: $gray-300 !default;

// Borders
$device-browser-border-radius: .5rem !default;
$device-browser-header-border-width: $border-width !default;
$device-browser-header-border-color: lighten($gray-300, 3%) !default;
$device-browser-header-btn-list-btn-border-radius: $border-radius-rounded !default;
$device-browser-header-browser-bar-border-radius: .125rem !default;

// Width & Heights
$devices-max-width: 1140px !default;
$device-vertical-tablet-width: 30rem !default;
$device-browser-width: 50rem !default;
$device-browser-lg-width: 60rem !default;
$device-browser-header-btn-list-btn-width: .5rem !default;
$device-browser-header-btn-list-btn-height: $device-browser-header-btn-list-btn-width !default;

// Shadows
$device-browser-box-shadow: 0 2.75rem 5.5rem -3.5rem rgba($gray-400, .5), 0 2rem 4rem -2rem rgba($dark, .3) !default;

// Type
$device-browser-header-browser-bar-font-size: $font-size-base * .5 !default;

// Positions
$device-browser-header-btn-list-btn-start-offset: 1rem !default;

// Margins
$devices-margin-y: 5rem !default;
$devices-margin-x: .75rem !default;
$devices-margin-x-offset: 5rem !default;

// Padding
$devices-padding-y: $devices-margin-y + 2rem !default;
$device-browser-header-padding-y: .5rem !default;
$device-browser-header-padding-x: 6.5rem !default;

// Margins
$devices-mobile-margin-x: .5rem !default;

// Gap
$devices-rotated-gap: 1rem !default;
$device-browser-header-btn-list-btn-gap: .25rem !default;

// Animations
$devices-rotated-deg: -22deg !default;
$devices-rotated-translate3d: 15%, 0, 0 !default;


// Circles
// =========================================================

// Borders
$circles-chart-border-radius: $border-radius-rounded !default;


// Dropzone
// =========================================================

// Color
$dropzone-size-color: $gray-700 !default;
$dropzone-initials-color: $primary !default;
$dropzone-error-color: $danger !default;
$dropzone-success-color: $success !default;

// Background
$dropzone-card-bg-color: $gray-200 !default;
$dropzone-filepreview-bg-color: $white !default;
$dropzone-initials-bg-color: rgba($primary, .1) !default;

// Borders
$dropzone-card-border-width: $border-width * 2 !default;
$dropzone-card-border-type: dashed !default;
$dropzone-card-border-color: $border-color !default;
$dropzone-card-border-radius: $border-radius !default;
$dropzone-filepreview-border-radius: $border-radius !default;
$dropzone-img-border-radius: $border-radius !default;
$dropzone-initials-border-radius: $border-radius !default;

// Shadow
$dropzone-filepreview-shadow: $box-shadow-sm !default;

// Width & Heights
$dropzone-img-max-width: $avatar-width !default;
$dropzone-initials-width: $btn-icon-width !default;
$dropzone-initials-height: $dropzone-initials-width !default;

// Type
$dropzone-title-font-size: $font-size-xs !default;
$dropzone-title-font-weight: $font-weight-semi-bold !default;
$dropzone-size-font-size: 80% !default;
$dropzone-initials-font-size: $btn-icon-font-size !default;
$dropzone-initials-font-weight: $font-weight-semi-bold !default;
$dropzone-initials-line-height: $dropzone-initials-height !default;

// Padding
$dropzone-card-padding-y: 1.5rem !default;
$dropzone-card-padding-x: $dropzone-card-padding-y !default;
$dropzone-filepreview-padding-y: 1rem !default;
$dropzone-filepreview-padding-x: $dropzone-filepreview-padding-y !default;

// Margins
$dropzone-details-margin-y: 1rem !default;
$dropzone-filename-margin-y: .25rem !default;
$dropzone-progress-margin-y: 1rem !default;
$dropzone-img-margin-x: .75rem !default;
$dropzone-initials-margin-x: $dropzone-img-margin-x !default;


// Go To
// =========================================================

// Colors
$go-to-color: $gray-700 !default;
$go-to-hover-color: $white !default;

// Backgrounds
$go-to-bg-color: rgba($secondary, .1) !default;
$go-to-bg-hover-color: $primary !default;

// Borders
$go-to-border-radius: $border-radius-rounded !default;

// Type
$go-to-font-size: $btn-icon-font-size !default;

// Width & Heights
$go-to-width: $btn-icon-width !default;
$go-to-height: $go-to-width !default;
$go-to-wave-width: 13.125rem !default;

// Z-Index
$go-to-z-index: 9999 !default;

// Opacity
$go-to-opacity: .5 !default;
$go-to-opacity-on-hover: 1 !default;

// Animations
$go-to-transition: $transition-timing ease-out !default;


// Fancybox
// =========================================================

// Backgrounds
$fancybox-bg-color: $gray-900 !default;
$fancybox-progress-bg-color: $primary !default;

// Others
$fancybox-blur: blur(30px) !default;


// Tom Select
// =========================================================

// Colors
$select-active-color: $headings-color !default;
$select-input-placeholder-color: $input-placeholder-color !default;
$select-selection-tag-color: $headings-color !default;
$select-tag-remove-icon-color: $text-muted !default;
$select-tag-remove-icon-hover-color: $active-color !default;
$select-tag-input-color: $headings-color !default;

// Backgrounds
$select-active-bg-color: rgba($gray-400, .3) !default;
$select-selection-tag-bg-color: $gray-300 !default;
$select-tag-input-bg-color: rgba($dark, .1) !default;

// Borders
$select-dropdown-input-wrap-border-color: $input-border-color !default;
$select-dropdown-input-wrap-border-width: $input-border-width !default;
$select-dropdown-input-wrap-border-radius: $input-border-radius !default;
$select-selection-tag-border-radius: 4px !default;

// Width & Heights
$select-option-pseudo-width: 1rem !default;
$select-option-pseudo-height: 1rem !default;

// Type
$select-option-pseudo-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='#{$active-color}'><path d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/></svg>") !default;
$select-tag-font-size: .875rem !default;

// Position Spaces
$select-option-pseudo-right-offset: .5rem !default;

// Padding
$select-padding-y: .5rem !default;
$select-padding-x: 1rem !default;
$select-selection-tag-padding-y: .28rem !default;
$select-selection-tag-padding-x: .875rem !default;
$select-dropdown-input-wrap-padding: .4rem !default;


// Leaflet
// =========================================================

// Colors
$leaflet-bar-color: $body-color !default;
$leaflet-bar-hover-color: $link-hover-color !default;

// Backgrounds
$leaflet-bar-bg-color: $white !default;

// Borders
$leaflet-popup-wrapper-border-radius: $border-radius !default;
$leaflet-control-zoom-border-radius: $border-radius !default;

// Shadows
$leaflet-popup-wrapper-box-shadow: $box-shadow-sm !default;

// Width & Heights
$leaflet-min-height: 30rem !default;
$leaflet-bar-width: $font-size-base * 2 !default;
$leaflet-bar-height: $leaflet-bar-width !default;

// Type
$leaflet-control-zoom-font: $font-family-base !default;
$leaflet-control-zoom-font-weight: $font-weight-semi-bold !default;
$leaflet-control-zoom-font-size: $font-size-base !default;

// Z-index
$leaflet-z-index: 0 !default;

// Margins
$leaflet-popup-margin-y: 2.5rem !default;
$leaflet-control-zoom-margin-y: .25rem !default;


// Quantity Counter
// =========================================================

// Borders
$quantity-counter-border-width: $border-width !default;
$quantity-counter-border-color: $border-color !default;
$quantity-counter-border-radius: $input-border-radius !default;

// Width & Heights
$quantity-counter-min-width: 9rem !default;

// Text
$quantity-counter-btn-font-size: $font-size-lg !default;

// Padding
$quantity-counter-padding-y: .5rem !default;
$quantity-counter-padding-x: 1rem !default;


// Quill
// =========================================================

// Colors
$quill-editor-p-color: $input-color !default;
$quill-stroke-color: $body-color !default;
$quill-fill-color: $quill-stroke-color !default;
$quill-toolbar-btn-hover-color: $link-color !default;
$quill-btn-color: $white !default;

// Backgrounds
$quill-btn-bg-color: $primary !default;
$quill-btn-bg-hover-color: darken($primary, 7%) !default;
$quill-remove-bg-color: $white !default;
$quill-toolbar-btn-bg-hover-color: $gray-200 !default;

// Borders
$quill-border-color: $input-border-color !default;
$quill-toolbar-border-radius: $input-border-radius !default;
$quill-remove-border-width: $border-width !default;
$quill-remove-border-color: $btn-white-border-color !default;
$quill-tooltip-input-border-width: $border-width !default;
$quill-tooltip-input-border-color: $input-border-color !default;
$quill-tooltip-input-border-hover-color: $active-color !default;
$quill-tooltip-input-focus-border-color: $input-focus-border-color !default;
$quill-tooltip-pseudo-border-width: $quill-tooltip-input-border-width !default;
$quill-tooltip-pseudo-border-color: $quill-tooltip-input-border-color !default;

// Width & Heights
$quill-tooltip-min-width: 20rem !default;
$quill-toolbar-btn-width: $font-size-base * 2.5 !default;
$quill-toolbar-btn-height: $quill-toolbar-btn-width !default;
$quill-toolbar-btn-svg-width: 1rem !default;

// Type
$quill-editor-font-size: $font-size-base !default;
$quill-editor-font-family: $font-family-base !default;
$quill-tooltip-input-font-family: $font-family-base !default;
$quill-tooltip-input-font-size: $font-size-base !default;
$quill-tooltip-pseudo-font-family: $font-family-base !default;
$quill-tooltip-pseudo-font-weight: $font-weight-semi-bold !default;
$quill-tooltip-pseudo-font-size: $font-size-base !default;
$quill-btn-font-size: $font-size-base !default;

// Z-index
$quill-tooltip-z-index: 1 !default;

// Padding
$quill-toolbar-padding-y: .75rem !default;
$quill-toolbar-padding-x: $input-btn-padding-x !default;
$quill-editor-padding-y: $input-btn-padding-y !default;
$quill-editor-padding-x: $input-btn-padding-x !default;
$quill-toolbar-btn-padding-y: .25rem !default;
$quill-toolbar-btn-padding-x: $quill-toolbar-btn-padding-y !default;
$quill-tooltip-padding-y: 1.5rem !default;
$quill-tooltip-padding-x: $quill-tooltip-padding-y !default;
$quill-tooltip-input-padding-y: $input-btn-padding-y !default;
$quill-tooltip-input-padding-x: $input-btn-padding-x !default;
$quill-tooltip-pseudo-padding-y: 1rem !default;
$quill-btn-padding-y: $input-btn-padding-y !default;
$quill-btn-padding-x: $input-btn-padding-x !default;

// Margins
$quill-remove-margin-x: .5rem !default;
$quill-formats-button-margin-x: .25rem !default;
$quill-tooltip-pseudo-margin-y: 1.5rem !default;
$quill-tooltip-margin-y: 1.5rem !default;

// Shadow
$quill-tooltip-box-shadow: $box-shadow-lg !default;
$quill-tooltip-input-focus-box-shadow: $box-shadow !default;

// Animations
$quill-tooltip-input-transition: $transition-timing !default;
$quill-btn-transition: $transition-timing !default;


// Chartjs
// =========================================================

// Colors
$chartjs-tooltip-body-color: $white !default;
$chartjs-tooltip-header-color: $white-70 !default;

// Width & Heights
$chartjs-tooltip-arrow-pointer-pseudo-width: 1rem !default;
$chartjs-tooltip-arrow-pointer-pseudo-height: $chartjs-tooltip-arrow-pointer-pseudo-width / 2 !default;

// Backgrounds
$chartjs-tooltip-bg-color: $dark !default;
$chartjs-tooltip-arrow-pointer-bg-size: $chartjs-tooltip-arrow-pointer-pseudo-width $chartjs-tooltip-arrow-pointer-pseudo-height !default;
$chartjs-tooltip-arrow-pointer-bg: str-replace(url("data:image/svg+xml,%3Csvg width='#{$chartjs-tooltip-arrow-pointer-pseudo-width}' height='#{$chartjs-tooltip-arrow-pointer-pseudo-height}' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 50 22.49'%3E%3Cpath fill='#{$chartjs-tooltip-bg-color}' d='M0,0h50L31.87,19.65c-3.45,3.73-9.33,3.79-12.85,0.13L0,0z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Borders
$chartjs-tooltip-border-radius: $border-radius !default;
$chartjs-tooltip-header-border-width: $border-width !default;
$chartjs-tooltip-header-border-color: rgba($white, .1) !default;

// Type
$chartjs-tooltip-font-size: $font-size-sm !default;
$chartjs-tooltip-header-font-weight: $font-weight-semi-bold !default;

// Padding
$chartjs-tooltip-padding-y: $input-btn-padding-y !default;
$chartjs-tooltip-padding-x: $input-btn-padding-x !default;

// Positions
$chartjs-tooltip-arrow-pointer-pseudo-position-x-offset: .6875rem !default;

// Animations
$chartjs-tooltip-transition: opacity $transition-timing-sm ease-in-out, left $transition-timing-sm ease, top $transition-timing-sm ease !default;


// Chartjs Doughnut
// =========================================================

// Positions
$chartjs-doughnut-custom-stat-top-offset: 8rem !default;


// Chartjs Matrix
// =========================================================

// Width & Heights
$chartjs-matrix-legend-height: 2.5rem !default;
$chartjs-matrix-legend-item-width: .625rem !default;
$chartjs-matrix-legend-item-height: $chartjs-matrix-legend-item-width !default;


// Datatable
// =========================================================

// Colors
$datatable-link-pagination-hover-color: $secondary !default;

// Backgrounds
$datatable-sort-arrow-active-bg: $primary !default;
$datatable-sort-arrow-bg: darken($gray-300, 3%) !default;

// Width & Height
$datatable-sort-arrow-width: .7rem !default;
$datatable-sort-arrow-height: $datatable-sort-arrow-width !default;
$datatable-custom-collapsible-control-pseudo-width: 1rem !default;
$datatable-custom-collapsible-control-pseudo-height: $datatable-custom-collapsible-control-pseudo-width !default;

// Margin
$datatable-link-sorting-pseudo-margin-x: .5rem !default;

// Others
$datatable-link-sorting-pseudo-bg: url("data:image/svg+xml,%3Csvg width='#{$datatable-sort-arrow-width}' height='#{$datatable-sort-arrow-height}' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23#{str-replace('#{$datatable-sort-arrow-bg}', '#')}'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23#{str-replace('#{$datatable-sort-arrow-bg}', '#')}'/%3E%3C/svg%3E%0A") !default;
$datatable-link-sorting-pseudo-asc-bg: url("data:image/svg+xml,%3Csvg width='#{$datatable-sort-arrow-width}' height='#{$datatable-sort-arrow-height}' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23#{str-replace('#{$datatable-sort-arrow-bg}', '#')}'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23#{str-replace('#{$datatable-sort-arrow-active-bg}', '#')}'/%3E%3C/svg%3E%0A") !default;
$datatable-link-sorting-pseudo-desc-bg: url("data:image/svg+xml,%3Csvg width='#{$datatable-sort-arrow-width}' height='#{$datatable-sort-arrow-height}' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23#{str-replace('#{$datatable-sort-arrow-active-bg}', '#')}'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23#{str-replace('#{$datatable-sort-arrow-bg}', '#')}'/%3E%3C/svg%3E%0A") !default;
$datatable-custom-collapsible-control-pseudo-bg: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$body-color}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$datatable-custom-collapsible-control-pseudo-width $datatable-custom-collapsible-control-pseudo-height !default;
$datatable-custom-collapsible-control-pseudo-active-bg: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$body-color}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$datatable-custom-collapsible-control-pseudo-width $datatable-custom-collapsible-control-pseudo-height !default;


// Flatpickr
// =========================================================

// Colors
$flatpickr-day-color: $headings-color !default;
$flatpickr-form-control-color: $primary !default;
$flatpickr-selected-end-start-range-color: $white !default;
$flatpickr-weekday-color: $gray-500 !default;
$flatpickr-day-hover-color: $link-color !default;
$flatpickr-day-today-color: $white !default;
$flatpickr-day-inrange-color: $flatpickr-day-today-color !default;
$flatpickr-day-prev-and-next-month-day-color: $gray-300 !default;
$flatpickr-day-prev-and-next-month-day-hover-color: $gray-400 !default;
$flatpickr-day-disabled-color: $disabled-color !default;
$flatpickr-day-disabled-hover-color: $flatpickr-day-disabled-color !default;
$flatpickr-day-in-range-selected-days-hover-color: $white !default;
$flatpickr-month-dropdown-color: $body-color !default;
$flatpickr-months-and-year-color: $flatpickr-month-dropdown-color !default;
$flatpickr-prev-next-btn-icon-color: $body-color !default;
$flatpickr-prev-next-btn-icon-hover-color: $link-color !default;

// Backgrounds
$flatpickr-bg-color: $white-bg !default;
$flatpickr-selected-end-start-range-bg-color: $active-bg-color !default;
$flatpickr-month-dropdown-hover-bg-color: transparent !default;
$flatpickr-day-hover-bg-color: $secondary-hover-bg-color !default;
$flatpickr-day-inrange-bg-color: $active-bg-color !default;
$flatpickr-day-today-bg-color: $active-bg-color !default;
$flatpickr-day-prev-and-next-month-day-hover-bg-color: $border-color !default;
$flatpickr-day-disabled-hover-bg-color: $border-color !default;
$flatpickr-day-in-range-selected-days-hover-bg-color: $flatpickr-day-inrange-bg-color !default;
$flatpickr-numinput-wrapper-hover-bg-color: transparent !default;
$flatpickr-time-hover-bg-color: rgba($gray-300, .5) !default;
$flatpickr-prev-next-btn-icon-bg-color: $btn-ghost-bg-color !default;
$flatpickr-prev-next-btn-icon-hover-bg-color: $secondary-hover-bg-color !default;

// Borders
$flatpickr-border-radius: $card-border-radius !default;
$flatpickr-selected-end-start-range-border-color: $flatpickr-selected-end-start-range-bg-color !default;
$flatpickr-pseudo-border-width: .5rem !default;
$flatpickr-pseudo-border-color: $flatpickr-bg-color !default;
$flatpickr-day-today-border-color: transparent !default;
$flatpickr-day-prev-and-next-month-day-hover-border-color: $border-color !default;
$flatpickr-day-disabled-hover-border-color: $border-color !default;
$flatpickr-day-in-range-selected-days-hover-border-color: $flatpickr-day-in-range-selected-days-hover-bg-color !default;
$flatpickr-prev-next-btn-icon-border-radius: $border-radius-rounded !default;

// Width & Height
$flatpickr-width: 21.125rem !default;
$flatpickr-custom-min-width: 7rem !default;
$flatpickr-prev-next-btn-icon-width: $btn-icon-width-sm !default;
$flatpickr-prev-next-btn-icon-height: $flatpickr-prev-next-btn-icon-width !default;

// Positions
$flatpickr-pseudo-left-offset: 1rem !default;

// Type
$flatpickr-weekday-font-weight: $font-weight-semi-bold !default;
$flatpickr-month-dropdown-font-size: $font-size-base !default;
$flatpickr-months-dropdown-font-weight: $font-weight-semi-bold !default;
$flatpickr-months-and-year-font-weight: $flatpickr-months-dropdown-font-weight !default;
$flatpickr-months-and-year-font-size: $flatpickr-month-dropdown-font-size !default;
$flatpickr-prev-next-btn-icon-line-height: $flatpickr-prev-next-btn-icon-height !default;

// Shadows
$flatpickr-box-shadow: $dropdown-box-shadow !default;
$flatpickr-day-inrange-box-shadow: -.35rem 0 0 $flatpickr-day-inrange-bg-color, .35rem 0 0 $flatpickr-day-inrange-bg-color !default;

// Padding
$flatpickr-padding-y: 1rem !default;
$flatpickr-padding-x: 1rem !default;

// Margin
$flatpickr-day-rangeMode-margin-top-offset: .25rem !default;
$flatpickr-months-margin-y: .75rem !default;

// Animations
$flatpickr-active-open-animation: fadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1) !default;


// Daterangepicker
// =========================================================

// Colors
$daterangepicker-available-date-hover-color: $link-color !default;
$daterangepicker-active-date-color: $white !default;
$daterangepicker-in-range-color: $body-color !default;
$daterangepicker-week-color: $gray-500 !default;
$daterangepicker-off-date-color: $disabled-color !default;
$daterangepicker-select-color: $headings-color !default;
$daterangepicker-selected-color: $secondary !default;
$daterangepicker-prev-next-btn-icon-color: $body-color !default;
$daterangepicker-prev-next-btn-icon-hover-color: $link-color !default;
$daterangepicker-ranges-item-color: $dropdown-color !default;
$daterangepicker-ranges-item-active-color: $active-color !default;
$daterangepicker-ranges-item-hover-color: $link-hover-color !default;
$daterangepicker-select-hover-color: $link-hover-color !default;

// Backgrounds
$daterangepicker-bg-color: $dropdown-bg !default;
$daterangepicker-calendar-bg-color: $daterangepicker-bg-color !default;
$daterangepicker-active-date-bg-color: $active-bg-color !default;
$daterangepicker-available-date-hover-bg-color: $secondary-hover-bg-color !default;
$daterangepicker-in-range-bg-color: rgba($gray-300, .5) !default;
$daterangepicker-prev-next-btn-icon-hover-bg-color: $secondary-hover-bg-color !default;
$daterangepicker-ranges-item-active-bg-color: $secondary-hover-bg-color !default;
$daterangepicker-ranges-item-hover-bg-color: transparent !default;
$daterangepicker-off-date-bg-color: transparent !default;
$daterangepicker-select-bg-color: transparent !default;

// Borders
$daterangepicker-border-radius: $card-border-radius !default;
$daterangepicker-border-color: $border-color !default;
$daterangepicker-pseudo-border-color: $daterangepicker-bg-color !default;
$daterangepicker-pseudo-border-width: 0 !default;
$daterangepicker-calendar-table-border-spacing: .25rem !default;
$daterangepicker-available-date-border-radius: $border-radius-rounded !default;
$daterangepicker-prev-next-btn-border-radius: $border-radius-rounded !default;
$daterangepicker-start-date-border-right-radius: $border-radius-pill !default;
$daterangepicker-end-date-border-right-radius: $daterangepicker-start-date-border-right-radius !default;
$daterangepicker-select-border-radius: $border-radius !default;
$daterangepicker-select-border-color: $border-color !default;
$daterangepicker-ranges-item-border-radius: $dropdown-item-border-radius !default;

// Position
$daterangepicker-pseudo-top-offset: $daterangepicker-pseudo-border-width !default;
$daterangepicker-pseudo-left-offset: 1rem !default;

// Shadows
$daterangepicker-box-shadow: $dropdown-box-shadow !default;

// Width & Height
$daterangepicker-calendar-max-width: 18.75rem !default;
$daterangepicker-calendar-max-width-md-down: 19rem !default;
$daterangepicker-day-width: $btn-icon-width-sm !default;
$daterangepicker-day-height: $btn-icon-height-sm !default;
$daterangepicker-ranges-min-width: 10rem !default;
$daterangepicker-prev-next-btn-icon-width: $btn-icon-width-sm !default;
$daterangepicker-prev-next-btn-icon-height: $daterangepicker-prev-next-btn-icon-width !default;
$daterangepicker-select-width: 3.5rem !default;

// Z-index
$daterangepicker-z-index: $navbar-z-index - 1 !default;

// Type
$daterangepicker-prev-next-btn-icon-font-size: $font-size-base !default;
$daterangepicker-font-family: $font-family-base !default;
$daterangepicker-week-font-weight: $font-weight-semi-bold !default;
$daterangepicker-day-line-height: $daterangepicker-day-height - .25rem !default;
$daterangepicker-month-font-size: $font-size-base !default;
$daterangepicker-month-font-weight: $font-weight-semi-bold !default;
$daterangepicker-day-font-size: $font-size-base !default;
$daterangepicker-buttons-btn-font-weight: $font-weight-normal !default;
$daterangepicker-ranges-item-font-size: $font-size-base !default;
$daterangepicker-select-font-size: $font-size-sm !default;

// Padding
$daterangepicker-padding-y: 1rem !default;
$daterangepicker-padding-x: 1rem !default;
$daterangepicker-ranges-padding-y: $dropdown-padding-y !default;
$daterangepicker-ranges-item-padding-y: $dropdown-item-padding-y !default;
$daterangepicker-ranges-item-padding-x: $dropdown-item-padding-x !default;
$daterangepicker-select-padding-y: .25rem !default;
$daterangepicker-select-padding-x: $daterangepicker-select-padding-y !default;

// Margins
$daterangepicker-selected-margin-y: 1rem !default;
$daterangepicker-margin-y: $daterangepicker-pseudo-top-offset !default;
$daterangepicker-ranges-item-margin-x: $dropdown-spacer !default;


// JSVectormap
// =========================================================

// Colors
$jvm-tooltip-color: $headings-color !default;
$jvm-zoom-color: $body-color !default;
$jvm-indicators-hover-color: $link-hover-color !default;

// Backgrounds
$jvm-zoom-bg-color: $white-bg !default;
$jvm-tooltip-bg-color: $white-bg !default;

// Borders
$jvm-indicators-border-radius: $border-radius-sm !default;
$jvm-zoom-border-width: $border-width !default;
$jvm-zoom-border-color: $border-color !default;

// Width & Height
$jvm-indicators-width: $btn-icon-width-xs !default;
$jvm-indicators-height: $jvm-indicators-width !default;

// Shadow
$jvm-tooltip-box-shadow: $box-shadow !default;

// Type
$jvm-tooltip-font-family: $font-family-base !default;
$jvm-tooltip-font-size: $font-size-base !default;

// Padding
$jvm-indicators-padding-y: $chartjs-tooltip-padding-y !default;
$jvm-indicators-padding-x: $chartjs-tooltip-padding-x !default;

// Margin
$jvm-indicators-margin: -$jvm-indicators-padding-x !default;

// Positions
$jvm-zoomin-indicator-top-offset: 1rem !default;
$jvm-zoomin-indicator-left-offset: $jvm-zoomin-indicator-top-offset !default;
$jvm-zoomout-indicator-top-offset: $jvm-zoomin-indicator-top-offset + $jvm-indicators-width + .3125rem !default;
$jvm-zoomout-indicator-left-offset: $jvm-zoomin-indicator-left-offset !default;

// Z-index
$jvm-tooltip-z-index: 1000 !default;

// Others
$jvm-tooltip-translate-value: 1rem 1rem !default;
$jvm-container-height-sm: 20rem !default;
$jvm-container-height-md: 25rem !default;
$jvm-container-height-lg: 30rem !default;


// Fullcalendar
// =========================================================

// Colors
$fullcalendar-cell-cushion-color: $flatpickr-weekday-color !default;
$fullcalendar-daygrid-day-number-color: $dark !default;
$fullcalendar-daygrid-day-number-active-color: $flatpickr-day-today-color !default;
$fullcalendar-daygrid-day-number-disabled-color: $flatpickr-day-prev-and-next-month-day-color !default;
$fullcalendar-btn-color: $body-color !default;
$fullcalendar-btn-hover-color: $btn-white-hover-color !default;
$fullcalendar-btn-active-color: $white !default;
$fullcalendar-event-color: $headings-color !default;
$fullcalendar-daygrid-block-event-color: $fullcalendar-event-color !default;
$fullcalendar-event-hover-color: $primary !default;
$fullcalendar-timeline-cell-cushion-hover-color: $link-hover-color !default;
$fullcalendar-header-cell-cushion-active-color: $active-color !default;
$fullcalendar-popover-close-hover-color: $link-hover-color !default;
$fullcalendar-list-day-color: $headings-color !default;
$fullcalendar-list-day-side-color: $body-color !default;

// Backgrounds
$fullcalendar-btn-bg-color: transparent !default;
$fullcalendar-btn-active-bg-color: $primary !default;
$fullcalendar-daygrid-day-number-bg-color: $primary !default;
$fullcalendar-daygrid-day-number-bg-active-color: $flatpickr-day-today-bg-color !default;
$fullcalendar-highlight-bg-color: rgba($primary, .1) !default;
$fullcalendar-disabled-bg-color: $disabled-bg-color !default;
$fullcalendar-event-bg-color: rgba($primary, .1) !default;
$fullcalendar-week-view-event-bg-color: $white !default;
$fullcalendar-popover-bg-color: $white-bg !default;
$fullcalendar-event-popover-header-bg-color: $gray-200 !default;
$fullcalendar-event-hs-team-bg-color: $primary !default;
$fullcalendar-event-reminders-bg-color: $danger !default;
$fullcalendar-event-tasks-bg-color: $warning !default;
$fullcalendar-event-holidays-bg-color: $info !default;
$fullcalendar-daygrid-event-dot-bg-color: $active-bg-color !default;
$fullcalendar-header-cell-cushion-active-bg-color: rgba($primary, .1) !default;
$fullcalendar-timegrid-now-indicator-arrow-bg-color: $danger !default;
$fullcalendar-list-event-hover-bg-color: transparent !default;
$fullcalendar-list-empty-bg-color: $gray-200 !default;

// Borders
$fullcalendar-border-color: $input-border-color !default;
$fullcalendar-btn-border-color: $btn-white-border-color !default;
$fullcalendar-btn-active-border-color: $primary !default;
$fullcalendar-daygrid-day-number-border-radius: $border-radius-rounded !default;
$fullcalendar-daygrid-block-event-border-radius: $border-radius !default;
$fullcalendar-event-border-radius: $border-radius !default;
$fullcalendar-timegrid-now-indicator-line-border-color: $fullcalendar-timegrid-now-indicator-arrow-bg-color !default;
$fullcalendar-timegrid-now-indicator-arrow-border-radius: $border-radius-rounded !default;
$fullcalendar-header-cell-cushion-border-radius: $border-radius !default;
$fullcalendar-popover-border-color: $card-border-color !default;
$fullcalendar-popover-border-radius: $card-border-radius !default;

// Width & Heights
$fullcalendar-daygrid-day-height: 10rem !default;
$fullcalendar-daygrid-day-number-width: $icon-width-sm !default;
$fullcalendar-daygrid-day-number-height: $fullcalendar-daygrid-day-number-width !default;
$fullcalendar-event-max-width-in-day-view: 15rem !default;
$fullcalendar-event-popover-width: 25rem !default;
$fullcalendar-timegrid-now-indicator-arrow-width: .5rem !default;
$fullcalendar-timegrid-now-indicator-arrow-height: $fullcalendar-timegrid-now-indicator-arrow-width !default;
$fullcalendar-timeline-event-height: 5rem !default;

// Type
$fullcalendar-btn-font-size: $font-size-base !default;
$fullcalendar-toolbar-title-font-size: $h3-font-size !default;
$fullcalendar-cell-cushion-font-size: $h6-font-size !default;
$fullcalendar-event-font-size: $font-size-sm !default;
$fullcalendar-event-font-weight: $font-weight-semi-bold !default;
$fullcalendar-event-time-font-size: $font-size-xs !default;
$fullcalendar-event-time-sticky-font-size: $font-size-sm !default;
$fullcalendar-list-font-weight: $font-weight-normal !default;

// Shadows
$fullcalendar-btn-box-shadow-hover: $btn-white-box-shadow-hover !default;
$fullcalendar-popover-box-shadow: $card-box-shadow !default;

// Padding
$fullcalendar-padding-y: .5rem !default;
$fullcalendar-padding-x: .5rem !default;
$fullcalendar-btn-padding-y: $input-btn-padding-y-sm !default;
$fullcalendar-btn-padding-x: $input-btn-padding-x-sm !default;
$fullcalendar-daygrid-day-frame-padding: .25rem !default;
$fullcalendar-daygrid-event-title-padding-y: .125rem !default;
$fullcalendar-event-padding-y: .3125rem !default;
$fullcalendar-event-padding-x: $fullcalendar-event-padding-y !default;
$fullcalendar-timeline-event-padding: .3125rem !default;
$fullcalendar-popover-padding-y: .5rem !default;
$fullcalendar-popover-padding-x: .75rem !default;

// Margins
$fullcalendar-daygrid-day-top-margin-y: .125rem !default;
$fullcalendar-event-margin-y: .125rem !default;


// Loading State
// =========================================================

// Backgrounds
$loader-wrapper-bg-color: $white-bg !default;

// Borders
$loader-wrapper-border-radius: $border-radius !default;
